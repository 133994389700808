<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title" placeholder="介绍标题" label="介绍标题" clearable></el-input>
        <el-select v-model="searchinfo.is_top" placeholder="是否置顶" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="不置顶" :value="0">不置顶</el-option>
          <el-option label="置顶" :value="1">置顶</el-option>
        </el-select>
        <el-select v-model="searchinfo.status" placeholder="介绍状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="禁用" :value="0">禁用</el-option>
          <el-option label="启用" :value="1">启用</el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="add('form')" icon="el-icon-plus">添加介绍</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            width="50px"
            type="index">
        </el-table-column>
        <el-table-column
            label="介绍标题"
            width="300" prop="title">
        </el-table-column>
        <el-table-column
            prop="content"
            min-width="120"
            label="介绍简介">
        </el-table-column>
        <el-table-column
            label="是否置顶"
            width="100" prop="is_top">
          <template slot-scope="scope">
            <el-link :type="scope.row.is_top?'success':'danger'"
                     @click="setenable(scope.row,'is_top')">{{ scope.row.is_top ? '置顶' : '不置顶' }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('global.state')"
            width="100" prop="status">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'"
                     @click="setenable(scope.row,'status')">{{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('language.created_at')"
            width="160" prop="created_at">
        </el-table-column>
        <el-table-column :label="$t('global.action')" fixed="right" width="100">
          <template slot-scope="scope">
            <el-link class="optlink" @click="edit(scope.row)">{{ $t('global.edit') }}</el-link>
            <el-link class="optlink" v-if="!scope.row.is_system" @click="del(scope.row)">{{ $t('global.delete') }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="noticeformdata.id?'编辑介绍':'操作介绍'" :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w90">
      <el-form :model="noticeformdata" :rules="rules" ref="form" :show-message="false">
        <el-form-item label="介绍标题" label-width="80px" prop="title" required>
          <el-input v-model="noticeformdata.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="介绍简介" label-width="80px" prop="content" required>
          <el-input type="textarea" v-model="noticeformdata.content"></el-input>
        </el-form-item>
        <el-form-item label="显示顺序" label-width="80px" prop="sort">
          <el-input type="number" v-model="noticeformdata.sort" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上传附件" label-width="80px" prop="file_path">
          <el-upload
              class="upload-demo"
              :action="uploadurl"
              :limit = "1"
              :headers="uploadheader"
              :auto-upload="true"
              :on-change="handleChange"
              :on-success="uploadsuccess"
              :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传文件，且不超过2M</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否置顶" label-width="80px">
          <el-switch v-model="noticeformdata.is_top" :active-value="1" :inactive-value="0"
                     :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item :label="$t('notice.status')" label-width="80px">
          <el-switch v-model="noticeformdata.status" :active-value="1" :inactive-value="0"
                     :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item label="详细内容" label-width="80px" prop="info">
          <vue-ueditor-wrap v-model="noticeformdata.info" :config="myConfig"></vue-ueditor-wrap>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('form')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'

export default {
  name: 'notice',
  components: {
    VueUeditorWrap
  },
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        title: '',
        type:3,
        status: '',
        is_top:''
      },
      list: [],
      totalRows: 0,
      defaultuserinfo: {
        notice_id: 0,
        title: '',
        file_path: '',
        content: '',
        sort: 0,
        type:3,
        is_top: 0,
        status: 1,
        info: '',
      },
      noticeformdata: {},
      rules: {
        title: [
          {min: 2, message: '介绍标题必须在2个字符以上', trigger: 'blur'}
        ],
      },
      isshowdialog: false,
      fileList:[],
      uploadurl: process.env.VUE_APP_API_ROOT+'/system/upload/uploadfile?storage=annex',
      uploadheader: {Authorization: this.$store.getters.getToken},
      isbusy: false,
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: '100%',
        serverUrl: '/static/UEditor/php/controller.php',
      }
    }
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    async edit(item) {
      this.noticeformdata = {};
      let file_list = [];
      if( item.file_path ){
        file_list.push({
          name: '附件',
          url : item.file_path
        });
      }
      this.fileList = file_list;
      this.noticeformdata = this.cloneData(item);
      delete this.noticeformdata.created_at;
      delete this.noticeformdata.updated_at;
      this.isshowdialog=true;
    },
    add(formName) {
      this.noticeformdata = {};
      this.noticeformdata = this.cloneData(this.defaultuserinfo);
      this.isshowdialog = true;
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/system/notice/index', this.searchinfo);
      if (res.code === 0) {
        this.list = res.data.data;
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/notice/save', this.noticeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item,field) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/notice/setenable', {nid: item.notice_id,field:field});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.list.findIndex((value) => {
            return value.id === item.id
          })
          if (idx !== -1) {
            if( field == 'status' ) this.list[idx].status = Math.abs(this.list[idx].status - 1);
            if( field == 'is_top' ) this.list[idx].is_top = Math.abs(this.list[idx].is_top - 1);
          }
          this.isbusy = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async del(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/notice/delete', {nid: item.notice_id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    uploadsuccess(res){
      if(res.code===0){
        if(res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
            && res.data[0].indexOf('http://')===-1){
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.noticeformdata.file_path = res.data[0];
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    }
  },
  mounted() {
    this.getlist(1);
  },
  computed: {
    baseurl: {
      get: function () {
        return this.$store.getters.getSerdomain;
      }
    },
    langlist: {
      get: function () {
        return this.$store.getters.getLanglist;
      }
    },
  }
}
</script>

<style>
.uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.uploader .el-upload:hover {
  border-color: #409EFF;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.preview {
  width: 178px;
  height: 50px;
  display: block;
}

.edui-popup-content.edui-default {
  height: auto !important;
}

.edui-combox-body.edui-default {
  height: 25px;

}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px;
}

.edui-default .edui-toolbar .edui-combox-body .edui-button-body {
  line-height: 25px !important;
}

.edui-default .edui-toolbar .edui-splitborder {
  height: 25px !important;
}

.edui-default .edui-toolbar .edui-combox-body .edui-arrow {
  height: 25px !important;
  width: 13px !important;
}

#edui_fixedlayer {
  z-index: 100000 !important;
}
</style>
