<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.entrust_no" placeholder="委托单号" label="委托单号" clearable></el-input>
        <el-input v-model="searchinfo.entrust_name" placeholder="委托人" label="委托人" clearable></el-input>
        <el-input v-model="searchinfo.entrust_mobile" placeholder="委托人电话" label="委托人电话" clearable></el-input>
        <el-input v-model="searchinfo.entrust_company" placeholder="委托公司" label="委托公司" clearable></el-input>
        <el-date-picker
                v-model="searchinfo.timerange"
                type="datetimerange"
                :picker-options="pickerOptions"
                :range-separator="$t('fundlogs.begintoend')"
                :start-placeholder="$t('fundlogs.begintime')"
                :end-placeholder="$t('fundlogs.endtime')"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                align="right">
        </el-date-picker>
        <el-select v-model="searchinfo.is_apply" placeholder="提审状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="待审核" :value="0">待审核</el-option>
          <el-option label="审核中" :value="1">审核中</el-option>
          <el-option label="通过审核" :value="2">通过审核</el-option>
          <el-option label="未通过审核" :value="3">未通过审核</el-option>
        </el-select>
        <el-select v-model="searchinfo.status" placeholder="委托单状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="待受理" :value="0">待受理</el-option>
          <el-option label="已受理" :value="1">已受理</el-option>
          <el-option label="拒绝受理" :value="2">拒绝受理</el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
        <el-button type="primary" @click="showExcel()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          导出
        </el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            width="50px"
            type="index">
        </el-table-column>
        <el-table-column
            label="委托单号"
            width="120"
            prop="entrust_no">
          <template slot-scope="scope">
            {{ !scope.row.entrust_no ? '******' : scope.row.entrust_no }}
          </template>
        </el-table-column>
        <el-table-column
            prop="entrust_name"
            width="100"
            label="委托人">
        </el-table-column>
        <el-table-column
            prop="entrust_company"
            width="100"
            label="委托单位">
        </el-table-column>
        <el-table-column
            prop="ypname"
            width="100"
            label="样品类别">
        </el-table-column>
        <el-table-column
            prop="jcname"
            width="100"
            label="检测类别">
        </el-table-column>
        <el-table-column
            prop="material_no"
            width="100"
            label="料号">
        </el-table-column>
        <el-table-column
            prop="version_no"
            width="100"
            label="版次">
        </el-table-column>
        <el-table-column
            prop="batch_no"
            width="100"
            label="批次">
        </el-table-column>
        <el-table-column
            width="120"
            label="送检数量">
          <template slot-scope="scope">
            {{ scope.row.sample_num }} {{ scope.row.dwname }}
          </template>
        </el-table-column>
        <el-table-column
            prop="entrust_name"
            width="100"
            label="检测项目">
        </el-table-column>
        <el-table-column
            width="160"
            label="委托单状态">
          <template slot-scope="scope">
            <span class="color-info" v-if="scope.row.is_apply == 0 || scope.row.is_apply == 3 || (scope.row.is_apply == 2 && scope.row.status == 2)">
              编辑中（待送审）
              <div style="color:red;" v-if="scope.row.is_apply == 3">[审核驳回]<br/>理由：{{scope.row.apply_remark}}</div>
              <div style="color:red;" v-if="scope.row.is_apply == 2 && scope.row.status == 2">[拒绝受理]<br/>理由：{{scope.row.deal_remark}}</div>
            </span>
            <span class="color-danger" v-else-if="scope.row.is_apply == 1">待审核（审核中）</span>
            <span class="color-warning" v-else-if="scope.row.is_apply == 2 && scope.row.status == 0">待受理</span>
            <span class="color-success" v-else-if="scope.row.is_apply == 2 && scope.row.status == 1 && scope.row.rcount <= 0">已受理</span>
            <span class="color-success" v-else-if="scope.row.is_apply == 2 && scope.row.status == 1 && (scope.row.detect_time || scope.row.rcount >= 1)">检测中</span>
            <span class="color-success" v-else-if="scope.row.is_apply == 2 && scope.row.status == 3">服务清单确认中</span>
            <span class="color-success" v-else-if="scope.row.is_apply == 2 && scope.row.status == 4">样品取回中</span>
            <span class="color-success" v-else>已结案</span>
          </template>
        </el-table-column>
        <el-table-column
            label="送审时间"
            width="160" prop="apply_time">
          <template slot-scope="scope">
            {{ scope.row.is_apply == 0 ? '---' : scope.row.apply_time }}
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            width="160" prop="created_at">
        </el-table-column>
        <el-table-column :label="$t('global.action')" fixed="right" width="100">
          <template slot-scope="scope">
            <div v-if="searchinfo.type == 'audio'">
              <el-link class="optlink" @click="edit(scope.row,'audio')" v-if="scope.row.is_apply == 1">立即审核</el-link>
              <el-link class="optlink" @click="edit(scope.row)" v-else>查看委托单</el-link>
            </div>
            <div v-else-if="searchinfo.type == 'deal'">
              <el-link class="optlink" @click="edit(scope.row,'deal')" v-if="scope.row.is_apply == 2 && scope.row.status == 0">立即受理</el-link>
              <el-link class="optlink" @click="edit(scope.row)" v-else>查看委托单</el-link>
            </div>
            <div v-else-if="searchinfo.type == 'detect'">
              <el-link class="optlink" @click="edit(scope.row)" v-if="scope.row.is_apply == 2 && scope.row.status == 1">检测作业</el-link>
              <el-link class="optlink" @click="edit(scope.row)" v-else>查看委托单</el-link>
            </div>
            <div v-else-if="searchinfo.type == 'report'">
              <el-link class="optlink" @click="edit(scope.row)" v-if="scope.row.is_apply == 2 && scope.row.status == 1">审核报告</el-link>
              <el-link class="optlink" @click="edit(scope.row)" v-else>查看委托单</el-link>
            </div>
            <div v-else-if="searchinfo.type == 'sure'">
              <el-link class="optlink" @click="edit(scope.row)" v-if="scope.row.is_apply == 2 && scope.row.status == 3">确认清单</el-link>
              <el-link class="optlink" @click="edit(scope.row)" v-else>查看清单</el-link>
            </div>
            <div v-else-if="searchinfo.type == 'sample'">
              <el-link class="optlink" @click="edit(scope.row)" v-if="scope.row.status == 4">取回样品</el-link>
              <el-link class="optlink" @click="edit(scope.row)" v-if="scope.row.status == 5">查看委托单</el-link>
            </div>
            <div v-else-if="searchinfo.type == 'search'">
              <el-link class="optlink" @click="edit(scope.row)">查看委托单</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="noticeformdata.entrust_id?(noticeformdata.entrust_no?noticeformdata.entrust_no:'编辑委托单'):'添加委托单'" :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w90">
      <el-form :model="noticeformdata" ref="form" :show-message="false">
        <div class="tip">用户信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="客户名称" label-width="100px" prop="custom_id" required>
              <el-select v-model="noticeformdata.custom_id" :disabled="disabled">
                <el-option value="">请选择客户</el-option>
                <template v-for="item in custom">
                <el-option :label="item.company" :key="item.custom_id" :value="item.custom_id">{{item.company}}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="委托人姓名" label-width="100px" prop="entrust_name" required>
              <el-input v-model="noticeformdata.entrust_name" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="委托人电话" label-width="100px" prop="entrust_mobile" required>
              <el-input v-model="noticeformdata.entrust_mobile" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="委托单位" label-width="90px" prop="entrust_company" required>
              <el-input v-model="noticeformdata.entrust_company" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="送样人姓名" label-width="100px" prop="sample_name" required>
              <el-input v-model="noticeformdata.sample_name" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="送样人电话" label-width="100px" prop="sample_mobile" required>
              <el-input v-model="noticeformdata.sample_mobile" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报告邮箱" label-width="90px" prop="sample_email" required>
              <el-input v-model="noticeformdata.sample_email" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">样品信息</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="样品类别" label-width="100px" prop="sample_type" required>
              <el-select v-model="noticeformdata.sample_type" :disabled="disabled">
                <el-option value="">请选择样品类别</el-option>
                <template v-for="item in yplist">
                <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测类别" label-width="100px" prop="test_type" required>
              <el-select v-model="noticeformdata.test_type" :disabled="disabled">
                <el-option value="">请选择检测类别</el-option>
                <template v-for="item in jclist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="机种名称" label-width="100px" prop="machine_type">
              <el-select v-model="noticeformdata.machine_type" :disabled="disabled">
                <el-option value="">请选择机种名称</el-option>
                <template v-for="item in jzlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="料件名称" label-width="100px" prop="material_name">
              <el-select v-model="noticeformdata.material_name" :disabled="disabled">
                <el-option value="">请选择料件名称</el-option>
                <template v-for="item in ljlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="材质" label-width="100px" prop="texture">
              <el-select v-model="noticeformdata.texture" :disabled="disabled">
                <el-option value="">请选择材质</el-option>
                <template v-for="item in czlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="料号" label-width="100px" prop="material_no" required>
              <el-input v-model="noticeformdata.material_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="版本" label-width="100px" prop="version_no" required>
              <el-input v-model="noticeformdata.version_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批次" label-width="90px" prop="batch_no">
              <el-input v-model="noticeformdata.batch_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="模号" label-width="100px" prop="templete_no">
              <el-input v-model="noticeformdata.templete_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="穴号" label-width="100px" prop="hole_no">
              <el-input v-model="noticeformdata.hole_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="送样数量" label-width="100px" prop="sample_num" required>
              <el-input v-model="noticeformdata.sample_num" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单位" label-width="100px" prop="sample_unit" required>
              <el-select v-model="noticeformdata.sample_unit" :disabled="disabled">
                <el-option value="">请选择单位</el-option>
                <template v-for="item in dwlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">样品保护</div>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="允许破坏" label-width="100px" prop="is_damage">
              <el-checkbox v-model="noticeformdata.is_damage" style="font-weight:300;" :disabled="disabled">(温馨提示：部分项目可能必须破坏方可测量)</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="保护需求" label-width="100px" prop="protect_id">
              <el-select multiple value-key="cate_id" v-model="noticeformdata.protect_id" :disabled="disabled">
                <el-option value="">请选择保护需求</el-option>
                <template v-for="item in bhlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item"></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="保密要求" label-width="100px" prop="is_secrecy">
              <el-radio-group v-model="noticeformdata.is_secrecy" :disabled="disabled">
              <template v-for="item in bmlist">
              <el-radio  :value="item.cate_id" :key="item.cate_id" :label="item.cate_id">{{ item.title }}</el-radio>
              </template>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">测量依据</div>
        <el-row>
          <el-col>
            <el-form-item label="上传附件" label-width="100px" prop="file_path">
              <template v-for="items in fileList">
                <ul class="showreport" style="margin-left:10px;">
                  <li><a :href="items.url" target="_blank"><i class="icon el-icon-paperclip"></i>{{ items.name }}</a></li>
                </ul>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">检测报告要求</div>
        <el-row>
          <el-col>
            <el-form-item label="报告格式" label-width="100px" prop="file_format" required>
              <el-radio-group v-model="noticeformdata.file_format" :disabled="disabled">
                <el-radio :label="1">由实验室根据测试需求决定</el-radio>
                <el-radio :label="2">依照[测量依据]中上传之报告范本</el-radio>
                <el-radio :label="3">参考最近需求</el-radio>
                <el-input style="display:inline-block; width:200px;" placeholder="请输入委托单号" :disabled="disabled" v-model="noticeformdata.old_no" autocomplete="off"></el-input>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="报告语言" label-width="100px" prop="language">
              <el-radio-group v-model="noticeformdata.language" :disabled="disabled">
                <el-radio :label="1">中文</el-radio>
                <el-radio :label="2">英文</el-radio>
                <el-radio :label="3">双语</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="长度解析度" label-width="100px" prop="leng_type">
              <el-select v-model="noticeformdata.leng_type" :disabled="disabled">
                <el-option value="">请选择长度解析度</el-option>
                <template v-for="item in jxlist">
                <el-option :label="item.label" :value="item.value" :key="item.label">{{ item.label }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="角度解析度" label-width="100px" prop="angle_type">
              <el-select v-model="noticeformdata.angle_type" :disabled="disabled">
                <el-option value="">请选择角度解析度</el-option>
                <template v-for="item in jxlist">
                  <el-option :label="item.label" :value="item.value" :key="item.label">{{ item.label }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他测量值解析度" label-width="150px" prop="other_type">
              <el-select v-model="noticeformdata.other_type" :disabled="disabled">
                <el-option value="">请选择其他测量值解析度</el-option>
                <template v-for="item in jxlist">
                  <el-option :label="item.label" :value="item.value" :key="item.label">{{ item.label }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">需求信息</div>
        <el-table ref="multipleTable"
                  :data="projectslist"
                  tooltip-effect="dark"
                  style="width: 100%">
          <el-table-column
              label="服务类别"
              width="160"
              prop="name">
          </el-table-column>
          <el-table-column
              prop="title"
              width="200"
              label="服务项目">
          </el-table-column>
          <el-table-column
              prop="number"
              width="180"
              label="测试数量">
          </el-table-column>
          <el-table-column
              prop="entrust_name"
              label="服务项目说明">
            <template slot-scope="scope">
              <el-popover
                  placement="top"
                  width="400"
                  trigger="click">
                <div>
                  <label style="display:inline-block; width:60px; vertical-align:top; font-weight: bolder;">样品要求</label>
                  <div style="display:inline-block; width:calc(100% - 65px); margin-left: 5px;">
                    {{ scope.row.project_demand }}
                  </div>
                </div>
                <div>
                  <label style="display:inline-block; width:60px; vertical-align:top; font-weight: bolder;">测试周期</label>
                  <div style="display:inline-block; width:calc(100% - 65px); margin-left: 5px;">
                    {{ scope.row.text_cycle }}
                  </div>
                </div>
                <div>
                  <label style="display:inline-block; width:60px; vertical-align:top; font-weight: bolder;">费用说明</label>
                  <div style="display:inline-block; width:calc(100% - 65px); margin-left: 5px;">
                    {{ scope.row.fee_remark }}
                  </div>
                </div>
                <el-button slot="reference"><i class="el-icon-search"></i> 查看样品要求、测试周期及费用说明</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div class="tip">附加说明</div>
        <el-row>
          <el-col>
            <el-form-item prop="explain_remark">
              <el-input type="textarea" :disabled="disabled" v-model="noticeformdata.explain_remark" placeholder="请输入附加说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">交期时间</div>
        <el-row>
          <el-col>
            <el-form-item label="交期等级" label-width="100px" prop="is_level">
              <el-radio-group v-model="noticeformdata.is_level" :disabled="disabled">
                <el-radio :label="1">一般件</el-radio>
                <el-radio :label="2">急件</el-radio>
                <el-radio :label="3">特急件</el-radio>
                <el-radio :label="4">立即投入</el-radio>
                <span>(由于检测室负载的不确定性，检测交期请与收样综合业务员当面确认，以综合业务员回复为准)</span>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">委托审核说明</div>
        <el-row>
          <el-col>
            <el-form-item prop="apply_remark">
              <el-input type="textarea" :disabled="disabled" v-model="noticeformdata.apply_remark" placeholder="请输入审核备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="searchinfo.type == 'audio'">
        <div class="tip">委托审核说明</div>
        <el-row>
          <el-col>
            <el-form-item prop="apply_remark">
              <el-input type="textarea" :disabled="otherdisabled" v-model="noticeformdata.apply_remark" placeholder="请输入审核备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        </div>
        <div v-if="searchinfo.type == 'deal'">
        <div class="tip">委托受理接收清单确认</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="样品图面" label-width="100px" prop="file_yppath">
              <el-upload
                  class="avatar-uploader"
                  :action="uploadurl"
                  :headers="uploadheader"
                  :show-file-list="false"
                  :on-success="uploadsuccessyp"
                  :before-upload="beforeupload"
                  :disabled="dealdisabled" >
                <img v-if="noticeformdata.file_yppath" :src="noticeformdata.file_yppath" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品图片" label-width="100px" prop="file_pic">
              <el-upload
                  class="avatar-uploader"
                  :action="uploadurl"
                  :headers="uploadheader"
                  :show-file-list="false"
                  :on-success="uploadsuccesspic"
                  :before-upload="beforeupload"
                  :disabled="dealdisabled" >
                <img v-if="noticeformdata.file_pic" :src="noticeformdata.file_pic" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他" label-width="100px" prop="file_other">
              <el-upload
                  class="avatar-uploader"
                  :action="uploadurl"
                  :headers="uploadheader"
                  :show-file-list="false"
                  :on-success="uploadsuccessqt"
                  :before-upload="beforeupload"
                  :disabled="dealdisabled" >
                <img v-if="noticeformdata.file_other" :src="noticeformdata.file_other" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item prop="deal_remark">
              <el-input type="textarea" :disabled="dealdisabled" v-model="noticeformdata.deal_remark" placeholder="请输入受理理由"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        </div>
        <div v-if="searchinfo.type != 'deal' && searchinfo.type != 'audio'">
          <div class="tip">委托受理接收清单确认</div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="样品图面" label-width="100px" prop="file_yppath">
                <img v-if="noticeformdata.file_yppath" :src="noticeformdata.file_yppath" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="样品图片" label-width="100px" prop="file_pic">
                <img v-if="noticeformdata.file_pic" :src="noticeformdata.file_pic" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="其他" label-width="100px" prop="file_other">
                <img v-if="noticeformdata.file_other" :src="noticeformdata.file_other" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item prop="deal_remark" >
                <el-input type="textarea" v-model="noticeformdata.deal_remark" disabled="disabled" placeholder="请输入受理理由"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="searchinfo.type == 'detect' || searchinfo.type == 'report' || searchinfo.type == 'sure' || searchinfo.type == 'sample' || searchinfo.type == 'search'">
        <div class="tip">检测作业</div>
        <el-row :gutter="20" v-if="noticeformdata.status == 1 && searchinfo.type == 'detect'">
          <el-form-item label="新增工站" label-width="100px">
            <el-select v-model="deviceform.device_id" @change="showproject" style="margin-right:20px;">
              <el-option label="请选择设备" :value="0"></el-option>
              <template v-for="item in device">
                <el-option :value="item.device_id" :key="item.device_id" :label="item.title"></el-option>
              </template>
            </el-select>
            <el-select v-model="deviceform.project_id" style="margin-right:20px;">
              <el-option label="请选择服务项目" :value="0"></el-option>
              <template v-for="item in deviceproject">
                <el-option :value="item.project_id" :key="item.project_id" :label="item.title"></el-option>
              </template>
            </el-select>
            <el-button :loading="isaddbusy" @click.stop="addGz">新增工站</el-button>
          </el-form-item>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <template v-if="searchinfo.type == 'detect'">
              <div style=" margin-bottom:10px;" v-for="item in gzlist">
                <span style="margin-left:100px; margin-right: 10px; display:inline-block; width:150px;">设备名称：{{ item.device }}</span>
                <span style="margin-right: 15px; display:inline-block; width: 150px;">服务项目：{{ item.project }}</span>
                <span style="margin-right: 15px; display:inline-block;" v-if="item.in_uid">投入人：{{item.realname}}</span>
                <span style="margin-right: 15px; display:inline-block;" v-if="item.in_time">投入时间：{{item.in_time}}</span>
                <span style="margin-right: 15px; display:inline-block;" v-if="item.finish_time">完工时间：{{item.finish_time}}</span>
                <span style="margin-right: 15px; display:inline-block;" v-if="item.finish_time">尺寸数：{{item.finish_num}}</span>
                <el-button :loading="addtimeload" @click.stop="addtime(item,'join')" v-if="item.showtr == true">投入</el-button>
                <span  v-else-if="item.showwg==true">
                  <el-input v-model="item.finish_num" autocomplete="off" style="display:inline-block; width:80px; margin-right:10px;" placeholder="尺寸数"></el-input>
                  <el-button :loading="addtimeload" type="primary" @click.stop="addtime(item,'finish')">完工</el-button>
                </span>
                <el-button :loading="addtimeload" type="danger" @click.stop="deletetime(item)" v-if="noticeformdata.is_apply==2 && noticeformdata.status == 1 && !item.finish_time">删除</el-button>
              </div>
            </template>
            <template v-else>
              <div style=" margin-bottom:10px;" v-for="item in gzlist">
                <span style="margin-left:100px; margin-right: 10px; display:inline-block; width:150px;">{{ item.device }}</span>
                <span style="margin-right: 10px; display:inline-block; width: 150px;">{{ item.project }}</span>
                <span style="margin-right: 10px; display:inline-block; width: 150px;">投入人：{{ item.realname }}</span>
                <span style="margin-right: 10px; display:inline-block;">投入时间：{{item.in_time}}</span>
                <span style="margin-right: 10px; display:inline-block;">完工时间：{{item.finish_time}}</span>
                <span style="margin-right: 10px; display:inline-block;">尺寸数：{{item.finish_num}}</span>
                <el-button :loading="addtimeload" type="danger" @click.stop="deletetime(item)" v-if="!item.in_time">删除</el-button>
              </div>
            </template>
          </el-col>
        </el-row>
        <div class="tip">报告上传与复核</div>
        <el-row>
          <el-col>
            <el-form-item label="送审报告" label-width="100px" v-if="noticeformdata.status == 1 && searchinfo.type == 'detect'">
              <el-upload
                  class="upload-demo"
                  :action="uploadurls"
                  :data="{en_id:noticeformdata.entrust_id}"
                  :headers="uploadheader"
                  :auto-upload="true"
                  :show-file-list="false"
                  :on-success="uploadsuccessed">
                <el-button size="small" type="primary">上传送审</el-button>
              </el-upload>
            </el-form-item>
            <template v-for="items in reportlist">
              <ul class="showreport" style="margin-left:25px;">
                <li :class="items.is_check==2?'ligreen':((items.is_check==1 || items.is_check==3) ? 'lired' :'')">{{ items.is_check == 0 ? '[待送审]' : (items.is_check == 1 ? '[已送审]' :(items.is_check == 2 ? '[审核通过]' : '[驳回审核]') ) }}</li>
                <li v-if="items.uid">上传人：{{items.realname}}</li>
                <li v-else>上传人：无</li>
                <li><a :href="thisurl+items.report_url" target="_blank" style="margin-right:10px;"><i class="icon el-icon-paperclip"></i>{{ items.report_name }}</a></li>
                <li v-if="items.cuid">审核人：{{items.crname}}</li>
                <li v-else>审核人：无</li>
                <li v-if="searchinfo.type == 'detect' || searchinfo.type == 'sure'">审核备注：{{ items.check_remark ? items.check_remark : '无' }}</li>
                <li v-if="searchinfo.type == 'detect'">
                  <el-button type="primary" :loading="addreportload" size="mini" @click.stop="goforreport(items)" v-if="items.is_check == 0">送审</el-button>
                  <el-button type="primary" :loading="addreportload" size="mini" @click.stop="deletereport(items)" v-if="items.is_check != 2 && items.is_check != 1">删除</el-button>
                </li>
                <li v-if="searchinfo.type == 'report' && items.is_check == 1"><span>审核备注：</span><el-input type="text" size="mini" v-model="items.check_remark" placeholder="请输入审核备注" style="display:inline-block; width:300px;"></el-input></li>
                <li v-if="searchinfo.type == 'report' && items.is_check != 1">审核备注：{{items.check_remark}}</li>
                <li v-if="searchinfo.type == 'report' && items.is_check == 1"><el-button type="danger" :loading="addreportload" size="mini" @click.stop="auditreport(items,'refuse')">驳回</el-button><el-button type="primary" :loading="addreportload" size="mini" @click.stop="auditreport(items,'agree')">通过</el-button></li>
              </ul>
            </template>
          </el-col>
        </el-row>
        </div>
        <div v-if="searchinfo.type == 'sure' || searchinfo.type == 'sample' || searchinfo.type == 'search'">
        <div class="tip">服务清单确认</div>
        <el-row :gutter="20" v-if="noticeformdata.status == 3">
          <el-form-item label="服务项目" label-width="100px">
            <el-select v-model="serviceform.project_id" style="margin-right:20px;" @change="getchooseproject">
              <el-option label="请选择项目" :value="0"></el-option>
              <template v-for="item in projectslist">
                <el-option :value="item.project_id" :key="item.project_id" :label="item.title"></el-option>
              </template>
            </el-select>
            <el-input style="display:inline-block; width:200px; margin-right:20px;" v-model="serviceform.content" placeholder="尺寸数" @input="showtotalprice"></el-input>
            <el-input style="display:inline-block; width:200px; margin-right:20px;" v-model="serviceform.perprice" disabled placeholder="服务单价" @input="showtotalprice"></el-input>
            <el-input style="display:inline-block; width:200px; margin-right:20px;" v-model="serviceform.totalprice" placeholder="服务总价"></el-input>
            <el-button :loading="isaddqdbusy" @click.stop="addQd">新增</el-button>
          </el-form-item>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <div style=" margin-bottom:10px;" v-for="item in qdlist">
              <span style="margin-left:20px; margin-right: 10px; display:inline-block; width:200px;">服务项目：{{ item.project }}</span>
              <span style="margin-right: 10px; display:inline-block; width: 150px;">尺寸数：{{ item.content }}</span>
              <span style="margin-right: 10px; display:inline-block; width: 150px;">服务单价：{{ item.perprice }}</span>
              <span style="margin-right: 10px; display:inline-block; width: 150px;">服务总计：{{ item.totalprice }}</span>
              <el-button :loading="qeleteqdload" type="danger" @click.stop="deleteqd(item)" v-if="noticeformdata.status == 3">删除</el-button>
            </div>
          </el-col>
        </el-row>
        </div>
        <div v-if="searchinfo.type == 'sample' || searchinfo.type == 'search'">
          <div class="tip">样品取回信息</div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="样品取走人" label-width="100px" prop="get_people">
                <el-input v-model="noticeformdata.get_people" autocomplete="off" :disabled="sampledisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="取走人联系方式" label-width="130px" prop="get_mobile">
                <el-input v-model="noticeformdata.get_mobile" autocomplete="off" :disabled="sampledisabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="tip">委托单履历</div>
        <el-collapse v-model="activeNamess" accordion>
          <el-collapse-item class="v-lv-list" title="委托单履历" name="1">
            <div v-for="item in lvlist"><span class="lv-time">时间：{{item.dotime}}</span><span class="lv-p">操作人：{{item.doname}}</span><span>操作：{{item.opdata}}</span></div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="searchinfo.type == 'audio'">
          <div style="display: inline-block; margin-right:10px;" v-if="noticeformdata.is_apply == 1">
            <el-button type="danger" @click="dosubmit('form','refuse')" :disabled="isbusy" :loading="isbusy">驳回</el-button>
            <el-button type="primary" @click="dosubmit('form','agree')" :disabled="isbusy" :loading="isbusy">批准</el-button>
          </div>
          <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
        </div>
        <div v-else-if="searchinfo.type == 'deal'">
          <div style="display: inline-block; margin-right:10px;" v-if="noticeformdata.status == 0">
            <el-button type="danger" @click="dosubmit('form','refuse')" :disabled="isbusy" :loading="isbusy">拒绝受理</el-button>
            <el-button type="primary" @click="dosubmit('form','agree')" :disabled="isbusy" :loading="isbusy">确认接收委托</el-button>
          </div>
          <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
        </div>
        <div v-else-if="searchinfo.type == 'detect'">
          <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
        </div>
        <div v-else-if="searchinfo.type == 'report'">
          <div style="display: inline-block; margin-right:10px;" v-if="noticeformdata.status == 1 && (noticeformdata.detect_uid > 0 || reportlist.length > 0)">
            <el-button type="primary" @click="dosubmit('form')" :disabled="isbusy" :loading="isbusy">检测作业完成</el-button>
          </div>
          <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
        </div>
        <div v-else-if="searchinfo.type == 'sure'">
          <div style="display: inline-block; margin-right:10px;" v-if="noticeformdata.status==3">
            <el-button type="primary" @click="dosubmit('form','agree')" :disabled="isbusy" :loading="isbusy">服务清单确认</el-button>
          </div>
          <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
        </div>
        <div v-else-if="searchinfo.type == 'sample'">
          <div style="display: inline-block; margin-right:10px;" v-if="noticeformdata.status==4">
            <el-button type="primary" @click="dosubmit('form','agree')" :disabled="isbusy" :loading="isbusy">取回样品</el-button>
          </div>
          <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
        </div>
        <div v-else-if="searchinfo.type == 'search'">
          <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="统计表导出" :visible.sync="isshowdialogs"
               :close-on-click-modal="false" custom-class="w60">
      <el-form :model="noticeformdata" ref="form" :show-message="false">
        <el-row>
          <el-col>
            <el-form-item label="选择导出类别" label-width="120px" required>
              <el-select v-model="isexceltype" @change="changeDownloadExcel">
                <el-option label="导出检测费用汇总表" value="0">导出检测费用汇总表</el-option>
                <el-option label="工作量明细表" value="1">工作量明细表</el-option>
                <el-option label="检测作业记录表" value="2">检测作业记录表</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div style="display: inline-block; margin-right:10px;">
          <el-button type="danger" @click="isshowdialogs=false" :disabled="isbusy" :loading="isbusy">取消</el-button>
<!--          <a :href="getDownUrl" target="_blank" style="margin-left:10px;">-->
            <el-button type="primary" @click="doexcel" :disabled="isbusy" :loading="isbusy">确定导出</el-button>
<!--          </a>-->
        </div>
      </div>
    </el-dialog>
  </el-main>
</template>
<script>
import tools from "@/libs/tools";
export default {
  name: 'entrustindex',
  components: {},
  data(){
    return {
      getDownUrl:'',
      pickerOptions: {
        shortcuts: [{
          text: this.$i18n.t('fundlogs.oneweek'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('fundlogs.onemonth'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: this.$i18n.t('fundlogs.threemonth'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      activeNamess:0,
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        timerange: [],
        //type:'audio',
        entrust_no: '',
        entrust_name:'',
        entrust_mobile:'',
        status: '',
        is_apply:'',
        entrust_company:''
      },
      isexceltype:'0',
      list: [],
      totalRows: 0,
      custom:[],
      lvlist:[],
      yplist:[],
      jclist:[],
      jzlist:[],
      ljlist:[],
      projectlist:[],
      czlist:[],
      dwlist:[],
      bhlist:[],
      bmlist:[],
      jxlist:[
        {label:"0",value:"0"},
        {label:"0.0",value:"0.0"},
        {label:"0.00",value:"0.00"},
        {label:"0.000",value:"0.000"},
      ],
      fileList:[],

      thisurl:process.env.VUE_APP_IMG_DOMAIN+'/storage/',
      uploadurl: process.env.VUE_APP_API_ROOT+'/system/upload/uploadfile?storage=entrust',
      uploadurls: process.env.VUE_APP_API_ROOT+'/system/entrust/uploadreport?storage=report',
      uploadheader: {Authorization: this.$store.getters.getToken},

      addtimeload:false,
      gzlist:[],
      addreportload:false,
      reportlist:[],
      deviceform:{
        device_id:'',
        project_id:''
      },
      isaddbusy:false,
      device:[],
      deviceproject:[],
      loginuid:0,


      qdlist:[],
      serviceform:{
        es_id:0,
        entrust_id:'',
        project_id:'',
        content:0,
        perprice:'0.00',
        totalprice:'0.00',
      },

      projectslist:[],
      projectsklist:[],
      projectsslist:[],

      noticeformdata: {},
      isbusy: false,
      disabled:true,
      sampledisabled:false,
      isaddqdbusy:false,
      qeleteqdload:false,
      isshowdialog:false,
      isshowdialogs:false,
      otherdisabled:false,
      isshowcheckbox:false,
      dealdisabled:false,
    }
  },
  methods:{
    getchooseproject(val){
      let chooses = {}; console.log(this.projectslist);
      this.projectslist.forEach((item)=>{
        if( item.project_id == val ){
          chooses = item;
        }
      })
      this.serviceform.perprice = chooses.fee_price;
      this.serviceform.totalprice = tools.mathMul(parseFloat(this.serviceform.perprice),parseFloat(this.serviceform.content));
    },
    changeDownloadExcel(){
      this.getDownUrl = '/api/system/entrust/getexcel?'
    },
    async doexcel(){
      this.searchinfo.isexcel = this.isexceltype;
      var jumpurl = '/api/system/entrust/getexcel?status='+this.searchinfo.status+'&start_time='+this.searchinfo.status+'&isexcel='+this.searchinfo.isexcel+'&is_apply='+this.searchinfo.is_apply+'&entrust_no='+this.searchinfo.entrust_no+'&entrust_name='+this.searchinfo.entrust_name+'&entrust_mobile='+this.searchinfo.entrust_mobile+'&entrust_company='+this.searchinfo.entrust_company+'&end_time='+this.searchinfo.end_time;
      window.open(jumpurl);
    },
    showtotalprice(){
      this.serviceform.totalprice = tools.mathMul(parseFloat(this.serviceform.perprice),parseFloat(this.serviceform.content));
    },
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async dosearch() {
      this.getlist(1);
    },
    showExcel(){
      this.isshowdialogs = true;
    },
    async getinfo(){
      let res = await this.httpget('/system/entrust/getinfo');
      if( res.code == 0 ){
        this.custom = res.data.custom;
        this.noticeformdata.entrust_name = res.data.info.realname;
        this.noticeformdata.entrust_mobile = res.data.info.mobile;
        this.noticeformdata.entrust_company = res.data.info.unitName;
        this.noticeformdata.sample_name = res.data.info.realname;
        this.noticeformdata.sample_mobile = res.data.info.mobile;
        this.noticeformdata.sample_email = res.data.info.email;
        this.yplist = res.data.yplist;
        this.jclist = res.data.jclist;
        this.jzlist = res.data.jzlist;
        this.ljlist = res.data.ljlist;
        this.czlist = res.data.czlist;
        this.dwlist = res.data.dwlist;
        this.bhlist = res.data.bhlist;
        this.bmlist = res.data.bmlist;
        this.device = res.data.device;
        this.projectlist = res.data.plist;
        this.projectslist = res.data.plist;
        this.projectsklist = res.data.plist;
        this.device.forEach((ks,is)=>{
          let depe = [];
          this.projectslist.forEach((item,idx)=>{
            ks.project.split(',').forEach((ds)=>{
              if(item.project_id == ds){
                depe.push({
                  project_id:item.project_id,
                  title:item.title
                });
              }
            });
          });
          ks['projects'] = depe;
        });
      }
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      if( this.searchinfo.timerange && this.searchinfo.timerange[0]){
        this.searchinfo.start_time = this.searchinfo.timerange[0];
      } else {
        this.searchinfo.start_time = "";
      }
      if( this.searchinfo.timerange && this.searchinfo.timerange[1]){
        this.searchinfo.end_time = this.searchinfo.timerange[1];
      } else {
        this.searchinfo.end_time = "";
      }
      this.isbusy = true;
      let res = await this.httpget('/system/entrust/index', this.searchinfo);
      if (res.code === 0) {
        this.list = res.data.data;
        this.loginuid = res.msg;
        this.list.forEach((item,index)=>{
          //样品类别
          this.yplist.forEach((yp,iyp)=>{
            if(item.sample_type == yp.cate_id){
              this.list[index].ypname = yp.title;
            }
          });
          //检测类别
          this.jclist.forEach((yp,iyp)=>{
            if(item.test_type == yp.cate_id){
              this.list[index].jcname = yp.title;
            }
          });
          //机种类别
          this.jzlist.forEach((yp,iyp)=>{
            if(item.machine_type == yp.cate_id){
              this.list[index].jzname = yp.title;
            }
          });
          //料件类别
          this.ljlist.forEach((yp,iyp)=>{
            if(item.material_name == yp.cate_id){
              this.list[index].ljname = yp.title;
            }
          });
          //材质
          this.czlist.forEach((yp,iyp)=>{
            if(item.texture == yp.cate_id){
              this.list[index].czname = yp.title;
            }
          });
          //单位
          this.dwlist.forEach((yp,iyp)=>{
            if(item.sample_unit == yp.cate_id){
              this.list[index].dwname = yp.title;
            }
          });
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    async edit(item,type) {
      this.noticeformdata = {};
      this.noticeformdata = this.cloneData(item);
      if( this.searchinfo.type == 'audio' ){
        if( this.noticeformdata.is_apply == 1 ){
          this.otherdisabled = false;
        } else {
          this.otherdisabled = true;
        }
      }
      delete this.noticeformdata.created_at;
      delete this.noticeformdata.updated_at;
      this.noticeformdata.is_damage = this.noticeformdata.is_damage ? true : false;

      this.noticeformdata.is_damage = this.noticeformdata.is_damage ? true : false;
      this.noticeformdata.sample_type = this.noticeformdata.sample_type == 0 ? '' : this.noticeformdata.sample_type;
      this.noticeformdata.test_type = this.noticeformdata.test_type == 0 ? '' : this.noticeformdata.test_type;
      this.noticeformdata.machine_type = this.noticeformdata.machine_type == 0 ? '' : this.noticeformdata.machine_type;
      this.noticeformdata.material_name = this.noticeformdata.material_name == 0 ? '' : this.noticeformdata.material_name;
      this.noticeformdata.texture = this.noticeformdata.texture == 0 ? '' : this.noticeformdata.texture;
      this.noticeformdata.sample_num = this.noticeformdata.sample_num == 0 ? '' : this.noticeformdata.sample_num;
      this.noticeformdata.sample_unit = this.noticeformdata.sample_unit == 0 ? '' : this.noticeformdata.sample_unit;

      this.fileList = this.noticeformdata.basis_url;
      let tagData = [];
      let tag = this.noticeformdata.protect_id.split(",");
      this.bhlist.forEach(item => {
        tag.forEach(code => {
          if (item.cate_id == code) {
            tagData.push(item);
          }
        });
      });
      this.noticeformdata.protect_id = tagData;
      this.projectsslist = JSON.parse(JSON.stringify(this.projectsklist));
      let prddss = [];
      this.projectlist.forEach((ts,index)=>{
        this.noticeformdata.eplist.forEach((ds)=>{
          if(ts.project_id == ds.project_id){
            ts.number = ds.number;
            prddss.push(ts);
          }
        });
      });
      this.projectslist = prddss;
      if( this.searchinfo.type == 'detect' || this.searchinfo.type == 'report' || this.searchinfo.type == 'sure' || this.searchinfo.type == 'sample' || this.searchinfo.type == 'search' ){
        this.getgz();
        this.getQd();
        this.getreport();
      }
      this.getLv();
      this.isshowdialog=true;
      if(this.noticeformdata.status == 5){
        this.sampledisabled = true;
      }
    },
    dosubmit(formName,dotype) {
      if( this.searchinfo.type == 'audio' && dotype == 'refuse' && !this.noticeformdata.apply_remark ){
        this.$message({type:'error',message:'请输入驳回理由'});
        return false;
      } else if( this.searchinfo.type == 'deal' && dotype == 'refuse' && !this.noticeformdata.deal_remark ){
        this.$message({type:'error',message:'请输入拒绝受理委托原因'});
        return false;
      } else {
        this.noticeformdata.dotype = dotype;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if( this.searchinfo.type == 'audio' && ( dotype == 'refuse' || dotype == 'agree' ) ){
              this.audiosubmit();
            } else if( this.searchinfo.type == 'deal' && ( dotype == 'refuse' || dotype=='agree' ) ) {
              this.dealsubmit();
            } else if( this.searchinfo.type == 'report' ){
              this.finishsubmit();
            } else if( this.searchinfo.type == 'sure' ){
              this.dsuresubmit();
            } else if( this.searchinfo.type == 'sample' ){
              this.samplesubmit();
            }
          } else {
            return false;
          }
        });
      }
    },
    async audiosubmit() {
      this.isbusy = true;
      let audioparam = {
        dotype:this.noticeformdata.dotype,
        apply_remark:this.noticeformdata.apply_remark,
        entrust_id:this.noticeformdata.entrust_id
      };
      this.noticeformdata.file_path = this.fileList;
      let res = await this.httppost('/system/entrust/audio', audioparam);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async dealsubmit() {
      this.isbusy = true;
      let dealparam = {
        dotype:this.noticeformdata.dotype,
        deal_remark:this.noticeformdata.deal_remark,
        file_yppath:this.noticeformdata.file_yppath,
        file_pic:this.noticeformdata.file_pic,
        file_other:this.noticeformdata.file_other,
        entrust_id:this.noticeformdata.entrust_id
      };
      this.noticeformdata.file_path = this.fileList;
      let res = await this.httppost('/system/entrust/deal', dealparam);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async finishsubmit() {
      this.isbusy = true;
      let finishparam = {
        entrust_id:this.noticeformdata.entrust_id
      };
      let res = await this.httppost('/system/entrust/detect', finishparam);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async dsuresubmit() {
      this.isbusy = true;
      let dsureparam = {
        entrust_id:this.noticeformdata.entrust_id,
        serviceform:this.serviceform
      };
      let res = await this.httppost('/system/entrust/dsure', dsureparam);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async samplesubmit() {
      this.isbusy = true;
      let sampleparam = {
        entrust_id: this.noticeformdata.entrust_id,
        get_people:this.noticeformdata.get_people,
        get_mobile:this.noticeformdata.get_mobile,
      };
      let res = await this.httppost('/system/entrust/getback', sampleparam);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getgz(){
      let res = await this.httpget('/system/entrust/getgz', {entrust_id:this.noticeformdata.entrust_id});
      if (res.code === 0) {
        this.gzlist = res.data;
        this.gzlist.forEach((item)=>{
          if( !item.in_time ){
            item.showtr = true;
            item.showwg = false;
            item.disabled = false;
          } else if( item.in_time && !item.finish_time ){
            item.showtr = false;
            item.showwg = true;
            item.disabled = false;
          } else {
            item.showtr = false;
            item.showwg = false;
            item.disabled = true;
          }
        })
        this.isaddbusy = false;
      } else {
        this.isaddbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getLv(){
      let res = await this.httpget('/system/entrust/getlv', {entrust_id:this.noticeformdata.entrust_id});
      if (res.code === 0) {
        this.lvlist = res.data;
      } else {
        this.$message({type: 'error', message: res.msg});
      }
    },
    async addGz(){
      this.isaddbusy = true;
      let res = await this.httppost('/system/entrust/addgz', {entrust_id:this.noticeformdata.entrust_id,device_id:this.deviceform.device_id,project_id:this.deviceform.project_id});
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        if( !res.data.in_time ){
          res.data.showtr = true;
          res.data.showwg = false;
          res.data.disabled = false;
        } else if( res.data.in_time && !res.data.finish_time ){
          res.data.showtr = false;
          res.data.showwg = true;
          res.data.disabled = false;
        } else {
          res.data.showtr = false;
          res.data.showwg = false;
          res.data.disabled = true;
        }
        this.gzlist.push(res.data);
        this.isaddbusy = false;
      } else {
        this.isaddbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getreport(){
      let res = await this.httpget('/system/entrust/getreport', {entrust_id:this.noticeformdata.entrust_id});
      if (res.code === 0) {
        this.reportlist = res.data;
      } else {
        this.isaddbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async addtime(item,type){
      this.addtimeload = true;
      item.entrust_id = this.noticeformdata.entrust_id;
      item.type = type;
      if( type == 'finish' && item.in_uid != parseInt(this.loginuid) ){
        this.addtimeload = false;
        this.$message({type: 'error', message: '您不是工站投入人，无法完成工站'});
        return false;
      }
      let res = await this.httppost('/system/entrust/updategz', item);
      if (res.code === 0) {
        this.$message({type: 'success', message: "已更新"});
        //this.getgz();
        let bditem = res.data;
        let newgzList = [];
        this.gzlist.forEach((iks)=>{
          if( iks.ew_id ==  bditem.ew_id ){
            if( !bditem.in_time ){
              bditem.showtr = true;
              bditem.showwg = false;
              bditem.disabled = false;
            } else if( bditem.in_time && !bditem.finish_time ){
              bditem.showtr = false;
              bditem.showwg = true;
              bditem.disabled = false;
            } else {
              bditem.showtr = false;
              bditem.showwg = false;
              bditem.disabled = true;
            }
            newgzList.push(bditem);
          } else {
            newgzList.push(iks);
          }
        })
        this.gzlist = newgzList;
        this.addtimeload = false;
      } else {
        this.addtimeload = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    deletetime(item){
      if( item.in_uid && item.in_uid != parseInt(this.loginuid) ){
        this.$message({type: 'error', message: '您不是工站投入人，无法删除工站'});
        return false;
      }
      this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.addtimeload = true;
        item.entrust_id = this.noticeformdata.entrust_id;
        let res = await this.httppost('/system/entrust/deletegz', item);
        if (res.code === 0) {
          this.$message({type: 'success', message: "已删除"});
          this.getgz();
          this.addtimeload = false;
        } else {
          this.addtimeload = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    showprojects(val){
      let dpr = [];
      this.projectslist.forEach((its)=>{
        this.device.forEach((item)=>{
          if( val == item.device_id && item.project.indexOf(its.project_id) != -1 ){
            dpr.push(its);
          }
        })
      })
      this.deviceproject = dpr;
      this.deviceform.project_id = "";
    },
    showproject(val){
      let dpr = [];
      this.device.forEach((item)=>{
        if( val == item.device_id ){
          dpr = item.projects;
        }
      });
      this.deviceproject = dpr;
      this.deviceform.project_id = "";
    },
    goforreport(item){
      if( item.uid != parseInt(this.loginuid) ){
        this.$message({type: 'error', message: '非上传报告人员，无法送审报告'});
        return false;
      }
      this.$confirm('确认送审报告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.addreportload = true;
        item.entrust_id = this.noticeformdata.entrust_id;
        let res = await this.httppost('/system/entrust/goaudioreport', item);
        if (res.code === 0) {
          this.$message({type: 'success', message: "已送审"});
          this.getreport();
          this.addreportload = false;
        } else {
          this.addreportload = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消送审'
        });
      });
    },
    deletereport(item){
      if( item.uid != parseInt(this.loginuid) ){
        this.$message({type: 'error', message: '非上传报告人员，无法删除报告'});
        return false;
      }
      this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.addreportload = true;
        item.entrust_id = this.noticeformdata.entrust_id;
        let res = await this.httppost('/system/entrust/deletereport', item);
        if (res.code === 0) {
          this.$message({type: 'success', message: "已删除"});
          this.getreport();
          this.addreportload = false;
        } else {
          this.addreportload = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    auditreport(item,dtype){
      if(dtype == 'refuse' && !item.check_remark){
        this.$message({type:"error",message:"请输入驳回理由"});
        return false;
      }
      this.$confirm('此操作该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.addreportload = true;
        item.entrust_id = this.noticeformdata.entrust_id;
        item.dtype = dtype;
        let res = await this.httppost('/system/entrust/auditreport', item);
        if (res.code === 0) {
          this.$message({type: 'success', message: "已成功"});
          this.getreport();
          this.addreportload = false;
        } else {
          this.addreportload = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },


    async getQd(){
      let res = await this.httpget('/system/entrust/getqd', {entrust_id:this.noticeformdata.entrust_id});
      if (res.code === 0) {
        this.qdlist = res.data;
        this.isaddbusy = false;
      } else {
        this.isaddbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async addQd(){
      this.isaddqdbusy = true;
      let qdparam = {
        entrust_id:this.noticeformdata.entrust_id,
        project_id:this.serviceform.project_id,
        content:this.serviceform.content,
        perprice:this.serviceform.perprice,
        totalprice:this.serviceform.totalprice
      };
      let res = await this.httppost('/system/entrust/addqd', qdparam);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.qdlist.push(res.data);
        this.isaddqdbusy = false;
      } else {
        this.isaddqdbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    deleteqd(item){
      if( item.uid != parseInt(this.loginuid) ){
        this.$message({type: 'error', message: '非创建清单人员，无法删除此清单'});
        return false;
      }
      this.$confirm('此操作将删除此清单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.qeleteqdload = true;
        item.entrust_id = this.noticeformdata.entrust_id;
        let res = await this.httppost('/system/entrust/deleteqd', item);
        if (res.code === 0) {
          this.$message({type: 'success', message: "已删除"});
          this.getQd();
          this.qeleteqdload = false;
        } else {
          this.qeleteqdload = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    uploadsuccessyp(res){
      if(res.code===0){
        if(res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
            && res.data[0].indexOf('http://')===-1){
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.noticeformdata.file_yppath = res.data[0];
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    },
    uploadsuccesspic(res){
      if(res.code===0){
        if(res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
            && res.data[0].indexOf('http://')===-1){
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.noticeformdata.file_pic = res.data[0];
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    },
    uploadsuccessqt(res){
      if(res.code===0){
        if(res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
            && res.data[0].indexOf('http://')===-1){
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.noticeformdata.file_other = res.data[0];
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    },
    uploadsuccessed(res){
      if(res.code===0){
        if(res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
            && res.data[0].indexOf('http://')===-1){
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.getreport();
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    },
    beforeupload(file) {
      const isJPG = (file.type === 'image/jpeg'||file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isJPG) {
        this.$message.error('Upload avatar pictures can only be in JPG or PNG format!');
      }
      if (!isLt2M) {
        this.$message.error('Upload avatar image size can\'t exceed 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
  watch:{
    '$route'(){
      let pagetype = this.$route.query.type;
      this.searchinfo.type = pagetype;
      this.getlist(1);
    }
  },
  mounted() {
    this.getinfo();
    let that = this;
    let pagetype = this.$route.query.type;
    this.searchinfo.type = pagetype;
    console.log(pagetype);
    setTimeout(function(){
      that.getlist(1);
    },500);
  },
}
</script>

<style>
.ligreen{
  color:darkgreen;
}
.v-lv-list span{
  display:inline-block;
  vertical-align: top;
}
.v-lv-list span.lv-time{
  width:190px;
}
.v-lv-list span.lv-p{
  width:160px;
}
.el-collapse-item__header{
  font-weight: bolder;
}
.lired{
  color:darkred;
}
.showreport{
  margin-bottom:5px;
}
.showreport li{
  display:inline-block;
  margin-right:10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.uploader .el-upload:hover {
  border-color: #409EFF;
}
.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.preview {
  width: 178px;
  height: 50px;
  display: block;
}
.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 5px 0px 10px;
}
.el-form-item{
  margin-bottom:12px;
}
.el-table__header-wrapper table thead th:first-child label{display:none;}
</style>
