<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.company" placeholder="请输入公司名称" label="公司名称" clearable></el-input>
        <el-input v-model="searchinfo.name" placeholder="请输入联系人" label="联系人" clearable></el-input>
        <el-input v-model="searchinfo.mobile" placeholder="请输入联系电话" label="联系电话" clearable></el-input>
        <el-select v-model="searchinfo.status" placeholder="客户状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="禁用" :value="0">禁用</el-option>
          <el-option label="启用" :value="1">启用</el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="adduser('userform')" icon="el-icon-plus">添加客户</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table
          ref="multipleTable"
          :data="userlist"
          tooltip-effect="dark"
          style="width: 100%">
        <el-table-column
            label="公司名称"
            width="120" prop="company">
        </el-table-column>
        <el-table-column
            label="联系人"
            width="120" prop="name">
        </el-table-column>
        <el-table-column
            label="联系电话"
            width="120" prop="mobile">
        </el-table-column>
        <el-table-column
            label="联系邮箱"
            width="120" prop="email">
        </el-table-column>
        <el-table-column
            label="联系地址"
            width="120" prop="address">
        </el-table-column>
        <el-table-column
            :label="$t('global.remarks')"
            prop="remark">
        </el-table-column>
        <el-table-column
            :label="$t('global.state')"
            width="120" prop="status">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'"
                     @click="setenable(scope.row)"
                     :disabled="scope.row.is_system===1">
              {{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column :label="$t('global.action')" fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink"  @click="edituser(scope.row)">{{$t('global.edit')}}</el-link>
            <el-link class="optlink"  @click="deluser(scope.row)">{{$t('global.delete')}}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getuserlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="userformdata.role_id?'编辑客户':'添加客户'" :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w60">
      <el-form :model="userformdata" :rules="rules" ref="userform" class="roleForm" :show-message="false">
        <el-form-item label="公司名称" label-width="80px" prop="company" required>
          <el-input v-model="userformdata.company" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系人" label-width="80px" prop="name" required>
          <el-input v-model="userformdata.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系手机" label-width="80px" prop="mobile" required>
          <el-input v-model="userformdata.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱" label-width="80px" prop="email">
          <el-input v-model="userformdata.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系地址" label-width="80px" prop="address">
          <el-input v-model="userformdata.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('global.state')" label-width="80px" required>
          <el-switch v-model="userformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')"
                     :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item label="备注信息" label-width="80px" prop="remark">
          <el-input type="textarea" v-model="userformdata.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'role',
  components: {},
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        company: '',
        name: '',
        mobile: '',
        status: '',
      },
      userlist: [],
      multipleSelection: [],
      totalRows: 0,
      defaultuserinfo: {
        custom_id: 0,
        company: '',
        name: '',
        mobile: '',
        email: '',
        status: 1,
        address:'',
        sort: 0,
        remark: '',
      },
      userformdata: {},
      rules: {
        company:[
          { required:true,message:'请输入公司名称',trigger:'blur' },
          { min:3,message:'长度大于3个字符',trigger:'blur' },
        ],
        name:[
          { required:true,message:'请输入联系人',trigger:'blur' },
          { min:2,message:'长度大于2个字符',trigger:'blur' },
        ],
        mobile:[
          { required:true,message:'请输入联系手机',trigger:'blur' },
        ],
      },
      isshowdialog: false,
      isbusy: false,
    }
  },
  methods: {
    SizeChange(val){
      this.searchinfo.pagesize=val;
      this.getuserlist(1);
    },
    async dosearch(){
      this.getuserlist(1);
    },
    async getuserlist(page){
      this.searchinfo.page=page||this.searchinfo.page;
      this.isbusy = true;
      let res= await this.httpget('/system/custom/index',this.searchinfo);
      if(res.code===0){
        this.userlist=res.data.data;
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    edituser(item){
      this.userformdata=null;
      item.password=undefined;
      this.userformdata = this.cloneData(item);
      this.isshowdialog=true;
    },
    adduser(formName){
      this.userformdata = JSON.parse(JSON.stringify(this.defaultuserinfo));
      this.isshowdialog=true;
      this.$nextTick(()=>{
        this.$refs[formName].resetFields();
      });
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit(){
      this.isbusy = true;
      let res= await this.httppost('/system/custom/save',this.userformdata);
      if(res.code===0){
        this.$message({type: 'success', message: res.msg});
        this.getuserlist(1);
        this.userformdata={};
        this.isshowdialog=false;
        this.isbusy = false;
      }else{
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item){
      this.$confirm(this.$t('global.areyousure')).then( async () => {
        this.isbusy = true;
        let res= await this.httpput('/system/custom/setenable',{cid:item.custom_id});
        if(res.code===0){
          this.$message({type: 'success', message: res.msg});
          let idx=this.userlist.findIndex((value) => {
            return value.uid === item.uid
          })
          if(idx!==-1){
            this.userlist[idx].status = Math.abs(this.userlist[idx].status-1);
          }
          this.isbusy = false;
        }else{
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async deluser(item){
      this.$confirm(this.$t('global.areyousure')).then( async () => {
        this.isbusy = true;
        let res= await this.httpput('/system/custom/delete',{cid:item.custom_id});
        if(res.code===0){
          this.$message({type: 'success', message: res.msg});
          this.getuserlist();
        }else{
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    }
  },
  mounted() {
    this.getuserlist(1);
  }
}
</script>
<style lang="scss">
.roleForm {
  .el-checkbox {
    padding-right: 10px !important;
  }
}
</style>
