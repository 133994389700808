<template>
  <el-main>
    <div style="background:#fff;">
      <el-form :inline="true" :model="userformdata" ref="userform" :show-message="false">
        <el-tabs type="border-card">
          <el-tab-pane label="基本设置">
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('setting.ip_filter')" label-width="150px" prop="ip_filter">
                  <el-radio-group v-model="userformdata.ip_filter">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('setting.auto_audio')" label-width="150px" prop="auto_audio">
                  <el-radio-group v-model="userformdata.auto_audio">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('setting.first_cash_min_money')" label-width="150px"
                              prop="first_cash_min_money">
                  <el-input type="number" v-model="userformdata.first_cash_min_money" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('setting.first_cash_max_money')" label-width="150px"
                              prop="first_cash_max_money">
                  <el-input type="number" v-model="userformdata.first_cash_max_money" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('setting.cash_min_money')" label-width="150px" prop="cash_min_money">
                  <el-input type="number" v-model="userformdata.cash_min_money" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('setting.cash_max_money')" label-width="150px" prop="cash_max_money">
                  <el-input type="number" v-model="userformdata.cash_max_money" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('setting.cash_times')" label-width="150px" prop="cash_times">
                  <el-input type="number" v-model="userformdata.cash_times" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('setting.cash_service_charge')" label-width="150px" prop="cash_service_charge">
                  <el-input type="number" v-model="userformdata.cash_service_charge" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('setting.refuse_time')" label-width="150px" prop="cash_times">
                  <el-input type="number" v-model="userformdata.refuse_time" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
              {{ $t('global.submit') }}
            </el-button>
          </el-tab-pane>
          <el-tab-pane label="客服信息设置" class="sertab">
            <el-form ref="serform" :model="serdata" label-width="120px">
              <el-form-item label="语言">
                <el-select v-model="serdata.lang" placeholder="语言" @change="getserinfo">
                  <el-option v-for="lang in langlist"
                             :key="lang.id"
                             :label="lang.name"
                             :value="lang.lang">
                    {{lang.name}}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="欢迎文案">
                <el-input type="textarea" v-model="serdata.welcome"></el-input>
              </el-form-item>
              <el-form-item label="客服标题文案">
                <el-input type="text" v-model="serdata.catetitle"></el-input>
              </el-form-item>
              <el-form-item label="在线客服文案">
                <el-input type="text" v-model="serdata.online_text"></el-input>
              </el-form-item>
              <el-form-item label="Line客服文案">
                <el-input type="text" v-model="serdata.line_text"></el-input>
              </el-form-item>
              <el-form-item label="Line客服号码">
                <el-input type="text" v-model="serdata.line_num"></el-input>
              </el-form-item>
              <el-form-item label="VIP客服文案">
                <el-input type="text" v-model="serdata.vip_text"></el-input>
              </el-form-item>
              <el-form-item label="提现Line账号">
                <el-input type="text" v-model="serdata.line_attr" placeholder="多个账号用,号隔开" @input="changeLineAttr"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="setservices('serform')">确定保存</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
  </el-main>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'setting',
  components: {},
  data() {
    return {
      userformdata: {},
      searchinfo: '',
      uploadurl: process.env.VUE_APP_API_ROOT + '/system/upload/images?storage=signimg',
      uploadheader: {Authorization: this.$store.getters.getToken},
      isbusy: false,
      serdata: {},
      defaultserdata: {
        lang: 'en_US',
        welcome: '',
        catetitle: '',
        online_text: '',
        line_text: '',
        vip_text: '',
        line_num: '',
        refuse_time:'',
      }
    }
  },
  methods: {
    async getlist() {
      this.isbusy = true;
      let res = await this.httpget('/system/setting/index', this.searchinfo);
      if (res.code === 0) {
        this.userformdata = res.data;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }
    },
    changeLineAttr(){
      this.serdata.line_attr = this.serdata.line_attr.replace(/，/g,",");
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit() {
      this.isbusy = true;
      let res = await this.httppost('/system/setting/save', this.userformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getserinfo(val){
      let lang = val?val:'en_US';
      let res = await this.httpget('/system/setting/getser', {lang: lang});
      if (res.code === 0) {
        this.serdata = res.data?res.data:{};
        if(!this.serdata.lang && val){
          this.serdata = this.cloneData(this.defaultserdata);
          this.serdata.lang = val;
        }
      }
    },
    async setservices(){
      this.isbusy = true;
      let res = await this.httppost('/system/setting/getser', this.serdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    uploadsuccess(res) {
      if (res.code === 0) {
        if (res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN) === -1
            && res.data[0].indexOf('http://') === -1) {
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        this.userformdata.picture = res.data[0];
      } else {
        this.$message({type: 'error', message: res.msg});
      }
    },
    beforeupload(file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isJPG) {
        this.$message.error('Upload avatar pictures can only be in JPG or PNG format!');
      }
      if (!isLt2M) {
        this.$message.error('Upload avatar image size can\'t exceed 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
  mounted() {
    this.getlist();
    this.getserinfo();
  },
  computed: {
    baseurl: {
      get: function () {
        return process.env.VUE_APP_API_ROOT;
      }
    },
    langlist: {
      get: function() {
        return this.$store.getters.getLanglist;
      }
    },
  },
}
</script>

<style lang="scss">
.tabs {
  padding: 20px 10px;
}
.sertab{
  .el-form{
    display: flex;
    flex-direction: column;
    .el-form-item{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .el-form-item__content{
    width: 100%;
    margin-left: 0px !important;
    margin-right: auto;
  }
  .el-textarea__inner{
    width: 100%;
  }
}
</style>
