<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.title" placeholder="请输入材质名称" label="材质名称" clearable></el-input>
        <el-select v-model="searchinfo.status" placeholder="材质状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="禁用" :value="0">禁用</el-option>
          <el-option label="启用" :value="1">启用</el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="adduser('userform')" icon="el-icon-plus">添加材质</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table
          ref="multipleTable"
          :data="userlist"
          tooltip-effect="dark"
          style="width: 100%">
        <el-table-column
            label="材质名称"
            width="120" prop="title">
        </el-table-column>
        <el-table-column
            :label="$t('global.remarks')"
            prop="remark">
        </el-table-column>
        <el-table-column
            :label="$t('global.state')"
            width="120" prop="status">
          <template slot-scope="scope">
            <el-link :type="scope.row.status?'success':'danger'"
                     @click="setenable(scope.row)"
                     :disabled="scope.row.is_system===1">
              {{ scope.row.status ? $t('global.enable') : $t('global.disable') }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
            :label="$t('global.action')"
            width="120"
            fixed="right">
          <template slot-scope="scope">
            <el-link class="optlink"  @click="edituser(scope.row)">{{$t('global.edit')}}</el-link>
            <el-link class="optlink"  @click="deluser(scope.row)">{{$t('global.delete')}}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getuserlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="userformdata.role_id?'编辑材质':'添加材质'" :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w50">
      <el-form :model="userformdata" :rules="rules" ref="userform" class="roleForm" :show-message="false">
        <el-form-item label="材质名称" label-width="80px" prop="title" required>
          <el-input v-model="userformdata.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="显示顺序" label-width="80px" prop="sort">
          <el-input type="number" v-model="userformdata.sort" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('global.state')" label-width="80px" required>
          <el-switch v-model="userformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')"
                     :inactive-text="$t('global.no')"></el-switch>
        </el-form-item>
        <el-form-item label="备注信息" label-width="80px" prop="remark">
          <el-input type="textarea" v-model="userformdata.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.submit') }}
        </el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'role',
  components: {},
  data() {
    return {
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        type:4,
        title: '',
        status: '',
      },
      userlist: [],
      multipleSelection: [],
      totalRows: 0,
      defaultuserinfo: {
        cate_id: 0,
        title: '',
        type:4,
        alaxname:'',
        status: 1,
        sort: 0,
        remark: '',
      },
      userformdata: {},
      rules: {
      },
      isshowdialog: false,
      isbusy: false,
    }
  },
  methods: {
    SizeChange(val){
      this.searchinfo.pagesize=val;
      this.getuserlist(1);
    },
    async dosearch(){
      this.getuserlist(1);
    },
    async getuserlist(page){
      this.searchinfo.page=page||this.searchinfo.page;
      this.isbusy = true;
      let res= await this.httpget('/system/category/index',this.searchinfo);
      if(res.code===0){
        this.userlist=res.data.data;
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    edituser(item){
      this.userformdata=null;
      this.userformdata = this.cloneData(item);
      this.isshowdialog=true;
    },
    adduser(formName){
      this.userformdata = JSON.parse(JSON.stringify(this.defaultuserinfo));
      this.isshowdialog=true;
      this.$nextTick(()=>{
        this.$refs[formName].resetFields();
      });
    },
    dosubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.suresubmit();
        } else {
          return false;
        }
      });
    },
    async suresubmit(){
      this.isbusy = true;
      let res= await this.httppost('/system/category/save',this.userformdata);
      if(res.code===0){
        this.$message({type: 'success', message: res.msg});
        this.getuserlist(1);
        this.userformdata={};
        this.isshowdialog=false;
        this.isbusy = false;
      }else{
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item){
      this.$confirm(this.$t('global.areyousure')).then( async () => {
        this.isbusy = true;
        let res= await this.httpput('/system/category/setenable',{cid:item.cate_id});
        if(res.code===0){
          this.$message({type: 'success', message: res.msg});
          let idx=this.userlist.findIndex((value) => {
            return value.uid === item.uid
          })
          if(idx!==-1){
            this.userlist[idx].status = Math.abs(this.userlist[idx].status-1);
          }
          this.isbusy = false;
        }else{
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async deluser(item){
      this.$confirm(this.$t('global.areyousure')).then( async () => {
        this.isbusy = true;
        let res= await this.httpput('/system/category/delete',{cid:item.cate_id});
        if(res.code===0){
          this.$message({type: 'success', message: res.msg});
          this.getuserlist();
        }else{
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    }
  },
  mounted() {
    this.getuserlist(1);
  }
}
</script>
<style lang="scss">
.roleForm {
  .el-checkbox {
    padding-right: 10px !important;
  }
}
</style>
