<template>
  <el-main>
    <div class="searchdiv">
      <div class="searchform">
        <el-input v-model="searchinfo.entrust_no" placeholder="委托单号" label="委托单号" clearable></el-input>
        <el-input v-model="searchinfo.entrust_name" placeholder="委托人" label="委托人" clearable></el-input>
        <el-input v-model="searchinfo.entrust_mobile" placeholder="委托人电话" label="委托人电话" clearable></el-input>
        <el-input v-model="searchinfo.entrust_company" placeholder="委托公司" label="委托公司" clearable></el-input>
        <el-select v-model="searchinfo.is_apply" placeholder="提审状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="待审核" :value="0">待审核</el-option>
          <el-option label="审核中" :value="1">审核中</el-option>
          <el-option label="通过审核" :value="2">通过审核</el-option>
          <el-option label="未通过审核" :value="3">未通过审核</el-option>
        </el-select>
        <el-select v-model="searchinfo.status" placeholder="委托单状态" @change="dosearch()" clearable>
          <el-option label="全部" value="">全部</el-option>
          <el-option label="待受理" :value="0">待受理</el-option>
          <el-option label="已受理" :value="1">已受理</el-option>
          <el-option label="拒绝受理" :value="2">拒绝受理</el-option>
        </el-select>
        <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">
          {{ $t('global.search') }}
        </el-button>
      </div>
      <div>
        <el-button type="primary" @click="add('form')" icon="el-icon-plus">新增委托单</el-button>
      </div>
    </div>
    <div class="pagecontent">
      <el-table ref="multipleTable"
                :data="list"
                tooltip-effect="dark"
                style="width: 100%">
        <el-table-column
            label="ID"
            width="50px"
            type="index">
        </el-table-column>
        <el-table-column
            label="委托单号"
            width="120"
            prop="entrust_no">
          <template slot-scope="scope">
            {{ !scope.row.entrust_no ? '******' : scope.row.entrust_no }}
          </template>
        </el-table-column>
        <el-table-column
            prop="entrust_name"
            width="100"
            label="委托人">
        </el-table-column>
        <el-table-column
            prop="entrust_company"
            width="100"
            label="委托单位">
        </el-table-column>
        <el-table-column
            prop="ypname"
            width="100"
            label="样品类别">
        </el-table-column>
        <el-table-column
            prop="jcname"
            width="100"
            label="检测类别">
        </el-table-column>
        <el-table-column
            prop="material_no"
            width="100"
            label="料号">
        </el-table-column>
        <el-table-column
            prop="version_no"
            width="100"
            label="版次">
        </el-table-column>
        <el-table-column
            prop="batch_no"
            width="100"
            label="批次">
        </el-table-column>
        <el-table-column
            width="120"
            label="送检数量">
          <template slot-scope="scope">
            {{ scope.row.sample_num }} {{ scope.row.dwname }}
          </template>
        </el-table-column>
        <el-table-column
            prop="entrust_name"
            width="100"
            label="检测项目">
        </el-table-column>
        <el-table-column
            width="160"
            label="委托单状态">
          <template slot-scope="scope">
            <span class="color-info" v-if="scope.row.is_apply == 0 || scope.row.is_apply == 3 || (scope.row.is_apply == 2 && scope.row.status == 2)">
              编辑中（待送审）
              <div style="color:red;" v-if="scope.row.is_apply == 3">[审核驳回]<br/>理由：{{scope.row.apply_remark}}</div>
              <div style="color:red;" v-if="scope.row.is_apply == 2 && scope.row.status == 2">[拒绝受理]<br/>理由：{{scope.row.deal_remark}}</div>
            </span>
            <span class="color-danger" v-else-if="scope.row.is_apply == 1">待审核（审核中）</span>
            <span class="color-warning" v-else-if="scope.row.is_apply == 2 && scope.row.status == 0">待受理</span>
            <span class="color-success" v-else-if="scope.row.is_apply == 2 && scope.row.status == 1 && !scope.row.detect_time && scope.row.rcount <= 0">已受理</span>
            <span class="color-success" v-else-if="scope.row.is_apply == 2 && scope.row.status == 1 && (scope.row.detect_time || scope.row.rcount >= 1)">检测中</span>
            <span class="color-success" v-else-if="scope.row.is_apply == 2 && scope.row.status == 3">服务清单确认中</span>
            <span class="color-success" v-else-if="scope.row.is_apply == 2 && scope.row.status == 4">样品取回中</span>
            <span class="color-success" v-else>已结案</span>
          </template>
        </el-table-column>
        <el-table-column
            label="送审时间"
            width="160" prop="apply_time">
          <template slot-scope="scope">
            {{ scope.row.is_apply == 0 ? '---' : scope.row.apply_time }}
          </template>
        </el-table-column>
        <el-table-column
            label="创建时间"
            width="160" prop="created_at">
        </el-table-column>
        <el-table-column :label="$t('global.action')" fixed="right" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.is_apply == 0 || scope.row.is_apply == 3 || (scope.row.is_apply == 2 && scope.row.status == 2)">
              <el-link class="optlink" @click="setenable(scope.row,'is_apply')">送审</el-link>
            <el-link class="optlink" @click="edit(scope.row)">{{ $t('global.edit') }}</el-link>
            <el-link class="optlink" @click="del(scope.row)">{{ $t('global.delete') }}</el-link>
            </div>
            <div v-else>
              <el-link class="optlink" @click="edit(scope.row)">查看委托单</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @size-change="SizeChange"
          @current-change="getlist"
          :current-page="searchinfo.page"
          :page-sizes="PAGE.pageSizes"
          :page-size="searchinfo.pagesize"
          :layout="PAGE.layout"
          :total="totalRows">
      </el-pagination>
    </div>
    <el-dialog :title="noticeformdata.entrust_id?(noticeformdata.entrust_no?noticeformdata.entrust_no:'编辑委托单'):'添加委托单'" :visible.sync="isshowdialog"
               :close-on-click-modal="false" custom-class="w90">
      <el-form :model="noticeformdata" :rules="isthissave==false ? ruless : rules" ref="form" :show-message="false">
        <div class="tip">用户信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="客户名称" label-width="100px" prop="custom_id" required>
              <el-select v-model="noticeformdata.custom_id" :disabled="disabled">
                <el-option value="">请选择客户</el-option>
                <template v-for="item in custom">
                <el-option :label="item.company" :key="item.custom_id" :value="item.custom_id">{{item.company}}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="委托人姓名" label-width="100px" prop="entrust_name" required>
              <el-input v-model="noticeformdata.entrust_name" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="委托人电话" label-width="100px" prop="entrust_mobile" required>
              <el-input v-model="noticeformdata.entrust_mobile" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="委托单位" label-width="90px" prop="entrust_company" required>
              <el-input v-model="noticeformdata.entrust_company" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="送样人姓名" label-width="100px" prop="sample_name" required>
              <el-input v-model="noticeformdata.sample_name" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="送样人电话" label-width="100px" prop="sample_mobile" required>
              <el-input v-model="noticeformdata.sample_mobile" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报告邮箱" label-width="90px" prop="sample_email" required>
              <el-input v-model="noticeformdata.sample_email" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">样品信息</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="样品类别" label-width="100px" prop="sample_type" required>
              <el-select v-model="noticeformdata.sample_type" :disabled="disabled">
                <el-option value="">请选择样品类别</el-option>
                <template v-for="item in yplist">
                <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测类别" label-width="100px" prop="test_type" required>
              <el-select v-model="noticeformdata.test_type" :disabled="disabled">
                <el-option value="">请选择检测类别</el-option>
                <template v-for="item in jclist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="机种名称" label-width="100px" prop="machine_type">
              <el-select v-model="noticeformdata.machine_type" :disabled="disabled">
                <el-option value="">请选择机种名称</el-option>
                <template v-for="item in jzlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="料件名称" label-width="100px" prop="material_name">
              <el-select v-model="noticeformdata.material_name" :disabled="disabled">
                <el-option value="">请选择料件名称</el-option>
                <template v-for="item in ljlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="材质" label-width="100px" prop="texture">
              <el-select v-model="noticeformdata.texture" :disabled="disabled">
                <el-option value="">请选择材质</el-option>
                <template v-for="item in czlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="料号" label-width="100px" prop="material_no" class="is-required">
              <el-input v-model="noticeformdata.material_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="版本" label-width="100px" prop="version_no" class="is-required">
              <el-input v-model="noticeformdata.version_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批次" label-width="90px" prop="batch_no">
              <el-input v-model="noticeformdata.batch_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="模号" label-width="100px" prop="templete_no">
              <el-input v-model="noticeformdata.templete_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="穴号" label-width="100px" prop="hole_no">
              <el-input v-model="noticeformdata.hole_no" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="送样数量" label-width="100px" prop="sample_num" class="is-required">
              <el-input type="number" v-model="noticeformdata.sample_num" autocomplete="off" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="单位" label-width="100px" prop="sample_unit" class="is-required">
              <el-select v-model="noticeformdata.sample_unit" :disabled="disabled">
                <el-option value="">请选择单位</el-option>
                <template v-for="item in dwlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item.cate_id">{{ item.title }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">样品保护</div>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="允许破坏" label-width="100px" prop="is_damage">
              <el-checkbox v-model="noticeformdata.is_damage" style="font-weight:300;" :disabled="disabled">(温馨提示：部分项目可能必须破坏方可测量)</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="保护需求" label-width="100px" prop="protect_id">
              <el-select multiple value-key="cate_id" v-model="noticeformdata.protect_id" :disabled="disabled">
                <el-option value="">请选择保护需求</el-option>
                <template v-for="item in bhlist">
                  <el-option :label="item.title" :key="item.cate_id" :value="item"></el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="保密要求" label-width="100px" prop="is_secrecy">
              <template v-for="item in bmlist">
              <el-radio :disabled="disabled" v-model="noticeformdata.is_secrecy" :value="item.cate_id" :key="item.cate_id" :label="item.cate_id">{{ item.title }}</el-radio>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">测量依据</div>
        <el-row>
          <el-col>
            <el-form-item label="上传附件" label-width="100px" prop="file_path">
              <el-upload
                  class="upload-demo"
                  :action="uploadurl"
                  :limit = "5"
                  :headers="uploadheader"
                  :auto-upload="true"
                  :show-file-list="false"
                  :on-success="uploadsuccess"
                  :on-preview="showPreview"
                  :disabled="disabled"
                  >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <template v-for="items in fileList">
              <ul class="showreport" style="margin-left:100px;">
                <li><a :href="items.url" target="_blank"><i class="icon el-icon-paperclip"></i>{{ items.name }}</a></li>
                <li v-if="noticeformdata.entrust_id == 0 || noticeformdata.is_apply == 0 || noticeformdata.is_apply == 3 || (noticeformdata.is_apply == 2 && noticeformdata.status == 2)">
                  <el-button type="primary" :loading="addreportload" size="mini" @click.stop="deletethisfile(items)">删除</el-button>
                </li>
              </ul>
            </template>
          </el-col>
        </el-row>
        <div class="tip">检测报告要求</div>
        <el-row>
          <el-col>
            <el-form-item label="报告格式" label-width="100px" prop="file_format" :required="isthissave?true:false">
              <el-radio-group v-model="noticeformdata.file_format" :disabled="disabled">
                <el-radio :label="1">由实验室根据测试需求决定</el-radio>
                <el-radio :label="2">依照[测量依据]中上传之报告范本</el-radio>
                <el-radio :label="3">参考最近需求</el-radio>
                <el-input style="display:inline-block; width:200px;" :disabled="disabled" placeholder="请输入委托单号" v-model="noticeformdata.old_no" autocomplete="off"></el-input>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="报告语言" label-width="100px" prop="language">
              <el-radio-group v-model="noticeformdata.language" :disabled="disabled">
                <el-radio :label="1">中文</el-radio>
                <el-radio :label="2">英文</el-radio>
                <el-radio :label="3">双语</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="长度解析度" label-width="100px" prop="leng_type">
              <el-select v-model="noticeformdata.leng_type" :disabled="disabled">
                <el-option value="">请选择长度解析度</el-option>
                <template v-for="item in jxlist">
                <el-option :label="item.label" :value="item.value" :key="item.label">{{ item.label }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="角度解析度" label-width="100px" prop="angle_type">
              <el-select v-model="noticeformdata.angle_type" :disabled="disabled">
                <el-option value="">请选择角度解析度</el-option>
                <template v-for="item in jxlist">
                  <el-option :label="item.label" :value="item.value" :key="item.label">{{ item.label }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他测量值解析度" label-width="150px" prop="other_type">
              <el-select v-model="noticeformdata.other_type" :disabled="disabled">
                <el-option value="">请选择其他测量值解析度</el-option>
                <template v-for="item in jxlist">
                  <el-option :label="item.label" :value="item.value" :key="item.label">{{ item.label }}</el-option>
                </template>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip"><span class="color-danger">*</span>需求信息</div>
        <el-table ref="multipleTable"
                  :data="projectlist"
                  tooltip-effect="dark"
                  style="width: 100%"
                  @selection-change="handleSelectionChange">
          <el-table-column
              type="selection"
              width="55" v-if="isshowcheckbox">
          </el-table-column>
          <el-table-column
              label="服务类别"
              width="160"
              prop="name">
          </el-table-column>
          <el-table-column
              prop="title"
              width="200"
              label="服务项目">
          </el-table-column>
          <el-table-column
              prop="number"
              width="180"
              label="测试数量">
            <template slot-scope="scope">
              <el-input v-model="scope.row.number" size="small" :disabled="disabled"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              prop="entrust_name"
              label="服务项目说明">
            <template slot-scope="scope">
              <el-popover
                  placement="top"
                  width="400"
                  trigger="click">
                <div>
                  <label style="display:inline-block; width:60px; vertical-align:top; font-weight: bolder;">样品要求</label>
                  <div style="display:inline-block; width:calc(100% - 65px); margin-left: 5px;">
                    {{ scope.row.project_demand }}
                  </div>
                </div>
                <div>
                  <label style="display:inline-block; width:60px; vertical-align:top; font-weight: bolder;">测试周期</label>
                  <div style="display:inline-block; width:calc(100% - 65px); margin-left: 5px;">
                    {{ scope.row.text_cycle }}
                  </div>
                </div>
                <div>
                  <label style="display:inline-block; width:60px; vertical-align:top; font-weight: bolder;">费用说明</label>
                  <div style="display:inline-block; width:calc(100% - 65px); margin-left: 5px;">
                    {{ scope.row.fee_remark }}
                  </div>
                </div>
                <el-button slot="reference"><i class="el-icon-search"></i> 查看样品要求、测试周期及费用说明</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div class="tip">附加说明</div>
        <el-row>
          <el-col>
            <el-form-item prop="explain_remark">
              <el-input type="textarea" :disabled="disabled" v-model="noticeformdata.explain_remark" placeholder="请输入附加说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">交期时间</div>
        <el-row>
          <el-col>
            <el-form-item label="交期等级" label-width="100px" prop="is_level">
              <el-radio-group v-model="noticeformdata.is_level" :disabled="disabled">
                <el-radio :label="1">一般件</el-radio>
                <el-radio :label="2">急件</el-radio>
                <el-radio :label="3">特急件</el-radio>
                <el-radio :label="4">立即投入</el-radio>
                <span>(由于检测室负载的不确定性，检测交期请与收样综合业务员当面确认，以综合业务员回复为准)</span>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="disabled == true">
          <div class="tip">委托审核说明</div>
          <el-row>
            <el-col>
              <el-form-item prop="apply_remark">
                <el-input type="textarea" :disabled="disabled" v-model="noticeformdata.apply_remark" placeholder="请输入审核备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="disabled == true">
        <div class="tip">委托受理接收清单确认</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="样品图面" label-width="100px" prop="file_yppath">
              <img v-if="noticeformdata.file_yppath" :src="noticeformdata.file_yppath" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品图片" label-width="100px" prop="file_pic">
              <img v-if="noticeformdata.file_pic" :src="noticeformdata.file_pic" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他" label-width="100px" prop="file_other">
              <img v-if="noticeformdata.file_other" :src="noticeformdata.file_other" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item prop="deal_remark" >
              <el-input type="textarea" v-model="noticeformdata.deal_remark" disabled="disabled" placeholder="请输入受理理由"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">检测作业</div>
        <el-row :gutter="10">
          <el-col :span="24">
            <div style=" margin-bottom:10px;" v-for="item in gzlist">
              <span style="margin-left:100px; margin-right: 10px; display:inline-block; width:150px;">{{ item.device }}</span>
              <span style="margin-right: 10px; display:inline-block; width: 150px;">{{ item.project }}</span>
              <span style="margin-right: 20px; display:inline-block;">投入时间【投入人：{{item.realname}}】：{{item.in_time}}</span>
              <span style="margin-right: 20px; display:inline-block;" v-if="item.finish_time">尺寸数：{{item.finish_num}}</span>
              <span style="margin-right: 10px; display:inline-block;">完工时间：{{item.finish_time}}</span>
            </div>
          </el-col>
        </el-row>
        <div class="tip">报告上传与复核</div>
        <el-row>
          <el-col>
            <template v-for="items in reportlist">
              <ul class="showreport" style="margin-left:25px;">
                <li :class="items.is_check==2?'ligreen':((items.is_check==1 || items.is_check==3) ? 'lired' :'')">{{ items.is_check == 0 ? '[待送审]' : (items.is_check == 1 ? '[已送审]' :(items.is_check == 2 ? '[审核通过]' : '[驳回审核]') ) }}</li>
                <li><a :href="thisurl+items.report_url" target="_blank"><i class="icon el-icon-paperclip"></i>{{ items.report_name }}</a> <template v-if="items.uid">[上传人：{{items.realname}}]</template></li>
                <li>审核备注：{{items.check_remark}} [审核人：{{items.crname}}]</li>
              </ul>
            </template>
          </el-col>
        </el-row>
        <div class="tip">服务清单确认</div>
        <el-row :gutter="10">
          <el-col :span="24">
            <div style=" margin-bottom:10px;" v-for="item in qdlist">
              <span style="margin-left:20px; margin-right: 10px; display:inline-block; width:200px;">服务项目：{{ item.project }}</span>
              <span style="margin-right: 10px; display:inline-block; width: 150px;">尺寸数：{{ item.content }}</span>
              <span style="margin-right: 10px; display:inline-block; width: 150px;">确认人：{{ item.realname }}</span>
            </div>
          </el-col>
        </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer"  v-if="disabled==false">
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{ $t('global.cancel') }}
        </el-button>
        <el-button type="primary" @click="dosubmit('form','save')" :disabled="isbusy" :loading="isbusy">保存</el-button>
        <el-button type="primary" @click="dosubmit('form','audio')" :disabled="isbusy" :loading="isbusy">立即送审</el-button>
      </div>
      <div slot="footer" class="dialog-footer"  v-else>
        <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">关闭</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  name: 'myentrust',
  components: {
  },
  data() {
    return {
      disabled:false,
      searchinfo: {
        page: 1,
        pagesize: this.PAGE.pageSize,
        type:'user',
        entrust_no: '',
        entrust_name:'',
        entrust_mobile:'',
        status: '',
        is_apply:'',
        entrust_company:''
      },
      gzlist:[],
      qdlist:[],
      list: [],
      custom:[],
      yplist:[],
      jclist:[],
      jzlist:[],
      ljlist:[],
      projectlist:[],
      czlist:[],
      dwlist:[],
      bhlist:[],
      bmlist:[],
      jxlist:[
        {label:"0",value:"0"},
        {label:"0.0",value:"0.0"},
        {label:"0.00",value:"0.00"},
        {label:"0.000",value:"0.000"},
      ],
      isthissave:false,
      totalRows: 0,
      multipleSelection:[],
      defaultuserinfo: {
        entrust_id: 0,
        custom_id:'',
        entrust_name:'',
        entrust_mobile:'',
        entrust_company:'',
        sample_name:'',
        sample_mobile:'',
        sample_email:'',
        sample_type:'',
        test_type:'',
        machine_type:'',
        material_name:'',
        texture:'',
        material_no:'',
        version_no:'',
        batch_no:'',
        templete_no:'',
        hole_no:'',
        sample_num:'',
        sample_unit:'',
        is_damage:false,
        protect_id:[],
        is_secrecy:'',
        file_path:[],
        file_format:'',
        old_no:'',
        language:'1',
        leng_type:'',
        angle_type:'',
        other_type:'',
        explain_remark:'',
        is_level:"1",
      },
      noticeformdata: {},
      rules: {
        custom_id: [
          { required:true, message: '请选择客户名称', trigger: 'blur'}
        ],
        entrust_name: [
          { required:true, message: '请输入委托人姓名', trigger: 'blur'}
        ],
        entrust_mobile: [
          { required:true, message: '请输入委托人电话', trigger: 'blur'}
        ],
        entrust_company: [
          { required:true, message: '请输入委托单位', trigger: 'blur'}
        ],
        sample_name: [
          { required:true, message: '请输入送样人姓名', trigger: 'blur'}
        ],
        sample_mobile: [
          { required:true, message: '请输入送样人手机', trigger: 'blur'}
        ],
        sample_email: [
          { required:true, message: '请输入报告邮箱', trigger: 'blur'}
        ],
        sample_type: [
          { required:true, message: '请选择样品类别', trigger: 'blur'}
        ],
        test_type: [
          { required:true, message: '请选择检测类别', trigger: 'blur'}
        ],
        material_no: [
          { required:true, message: '请输入料号', trigger: 'blur'}
        ],
        version_no: [
          { required:true, message: '请输入版本号', trigger: 'blur'}
        ],
        sample_num: [
          { required:true, message: '请输入送样数量', trigger: 'blur'}
        ],
        sample_unit: [
          { required:true, message: '请选择单位', trigger: 'blur'}
        ],
      },
      ruless:{
        custom_id: [
          { required:true, message: '请选择客户名称', trigger: 'blur'}
        ],
        entrust_name: [
          { required:true, message: '请输入委托人姓名', trigger: 'blur'}
        ],
        entrust_mobile: [
          { required:true, message: '请输入委托人电话', trigger: 'blur'}
        ],
        entrust_company: [
          { required:true, message: '请输入委托单位', trigger: 'blur'}
        ],
        sample_name: [
          { required:true, message: '请输入送样人姓名', trigger: 'blur'}
        ],
        sample_mobile: [
          { required:true, message: '请输入送样人手机', trigger: 'blur'}
        ],
        sample_email: [
          { required:true, message: '请输入报告邮箱', trigger: 'blur'}
        ],
        sample_type: [
          { required:true, message: '请选择样品类别', trigger: 'blur'}
        ],
        test_type: [
          { required:true, message: '请选择检测类别', trigger: 'blur'}
        ],
        material_no: [
          { required:false, message: '请输入料号', trigger: 'blur'}
        ],
        version_no: [
          { required:false, message: '请输入版本号', trigger: 'blur'}
        ],
        sample_num: [
          { required:false, message: '请输入送样数量', trigger: 'blur'}
        ],
        sample_unit: [
          { required:false, message: '请选择单位', trigger: 'blur'}
        ],
      },
      isshowdialog: false,
      fileList:[],
      reportlist:[],
      addreportload:false,
      thisurl:process.env.VUE_APP_IMG_DOMAIN+'/storage/',
      uploadurl: process.env.VUE_APP_API_ROOT+'/system/upload/uploadfile?storage=entrust',
      uploadheader: {Authorization: this.$store.getters.getToken},
      isbusy: false,
      isshowcheckbox:true,
    }
  },
  methods: {
    deletethisfile(item){
      if( item.url && item.name ){
        let didx = '';
        this.fileList.forEach((items,idx)=>{
          if( items.url == item.url && items.name == item.name ){
            didx = idx
          }
        });
        if( didx !== '' ){
          delete this.fileList.splice(didx,1);
        }
      }
    },
    showPreview( file ){
      window.open(file.url);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    SizeChange(val) {
      this.searchinfo.pagesize = val;
      this.getlist(1);
    },
    showInfo(info){
      console.log(info);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async getreport(){
      let res = await this.httpget('/system/entrust/getreport', {entrust_id:this.noticeformdata.entrust_id,type:'myentrust'});
      if (res.code === 0) {
        this.reportlist = res.data;
      } else {
        this.isaddbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getQd(){
      let res = await this.httpget('/system/entrust/getqd', {entrust_id:this.noticeformdata.entrust_id});
      if (res.code === 0) {
        this.qdlist = res.data;
        this.isaddbusy = false;
      } else {
        this.isaddbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getgz(){
      let res = await this.httpget('/system/entrust/getgz', {entrust_id:this.noticeformdata.entrust_id});
      if (res.code === 0) {
        this.gzlist = res.data;
        this.gzlist.forEach((item)=>{
          if( !item.in_time ){
            item.showtr = true;
            item.showwg = false;
            item.disabled = false;
          } else if( item.in_time && !item.finish_time ){
            item.showtr = false;
            item.showwg = true;
            item.disabled = false;
          } else {
            item.showtr = false;
            item.showwg = false;
            item.disabled = true;
          }
        })
        this.isaddbusy = false;
      } else {
        this.isaddbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async getinfo(){
      let res = await this.httpget('/system/entrust/getinfo');
      if( res.code == 0 ){
        this.custom = res.data.custom;
        this.noticeformdata.entrust_name = res.data.info.realname;
        this.noticeformdata.entrust_mobile = res.data.info.mobile;
        this.noticeformdata.entrust_company = res.data.info.unitName;
        this.noticeformdata.sample_name = res.data.info.realname;
        this.noticeformdata.sample_mobile = res.data.info.mobile;
        this.noticeformdata.sample_email = res.data.info.email;
        this.yplist = res.data.yplist;
        this.jclist = res.data.jclist;
        this.jzlist = res.data.jzlist;
        this.ljlist = res.data.ljlist;
        this.czlist = res.data.czlist;
        this.dwlist = res.data.dwlist;
        this.bhlist = res.data.bhlist;
        this.bmlist = res.data.bmlist;
        this.projectlist = res.data.plist;
      }
    },
    async edit(item){
      this.noticeformdata = {};
      this.noticeformdata = this.cloneData(item);
      delete this.noticeformdata.created_at;
      delete this.noticeformdata.updated_at;
      this.noticeformdata.is_damage = this.noticeformdata.is_damage ? true : false;
      this.noticeformdata.sample_type = this.noticeformdata.sample_type == 0 ? '' : this.noticeformdata.sample_type;
      this.noticeformdata.test_type = this.noticeformdata.test_type == 0 ? '' : this.noticeformdata.test_type;
      this.noticeformdata.machine_type = this.noticeformdata.machine_type == 0 ? '' : this.noticeformdata.machine_type;
      this.noticeformdata.material_name = this.noticeformdata.material_name == 0 ? '' : this.noticeformdata.material_name;
      this.noticeformdata.texture = this.noticeformdata.texture == 0 ? '' : this.noticeformdata.texture;
      this.noticeformdata.sample_num = this.noticeformdata.sample_num == 0 ? '' : this.noticeformdata.sample_num;
      this.noticeformdata.sample_unit = this.noticeformdata.sample_unit == 0 ? '' : this.noticeformdata.sample_unit;
      this.fileList = this.noticeformdata.basis_url;
      let tagData = [];
      let tag = this.noticeformdata.protect_id.split(",");
      this.bhlist.forEach(item => {
        tag.forEach(code => {
          if (item.cate_id == code) {
            tagData.push(item);
          }
        });
      });
      this.noticeformdata.protect_id = tagData;
      (this.noticeformdata.is_apply == 0 || this.noticeformdata.is_apply ==3 || (this.noticeformdata.is_apply == 2 && this.noticeformdata.status == 2) )? this.isshowcheckbox = true : this.isshowcheckbox = false;
      if( this.noticeformdata.is_apply ==  0 || this.noticeformdata.is_apply == 3 || (this.noticeformdata.is_apply == 2 && this.noticeformdata.status == 2) ){
        //选择项目
        let newplist = [];
        let mSelection = [];
        this.projectlist.forEach((ts,index)=>{
          this.noticeformdata.eplist.forEach((ds)=>{
            if(ts.project_id == ds.project_id){
              ts.number = ds.number;
              mSelection.push(ds.project_id);
              newplist.push(ts);
            }
          });
        });
        this.$nextTick(()=>{
          this.projectlist.forEach(row=>{
            if(mSelection.indexOf(row.project_id) >= 0){
              this.$refs.multipleTable.toggleRowSelection(row,true);
            }
          })
        })
      } else {
        this.projectlist.forEach((ts,index)=>{
          this.noticeformdata.eplist.forEach((ds)=>{
            if(ts.project_id == ds.project_id){
              ts.number = ds.number;
            } else {
              delete this.projectlist[index];
            }
          });
        });
      }
      if( this.noticeformdata.is_apply == 1 || (this.noticeformdata.is_apply == 2 && this.noticeformdata.status != 2) ){
        this.disabled = true;
        if( this.noticeformdata.is_apply == 2 ){
          this.getgz();
          this.getQd();
          this.getreport();
        }
      } else {
        this.disabled = false;
      }
      this.isshowdialog=true;
    },
    add(formName) {
      this.noticeformdata = {
        entrust_id: 0,
        custom_id:'',
        entrust_name:'',
        entrust_mobile:'',
        entrust_company:'',
        sample_name:'',
        sample_mobile:'',
        sample_email:'',
        sample_type:'',
        test_type:'',
        machine_type:'',
        material_name:'',
        texture:'',
        material_no:'',
        version_no:'',
        batch_no:'',
        templete_no:'',
        hole_no:'',
        sample_num:'',
        sample_unit:'',
        is_damage:false,
        protect_id:[],
        is_secrecy:'',
        file_path:[],
        file_format:1,
        old_no:'',
        language:1,
        leng_type:'',
        angle_type:'',
        other_type:'',
        explain_remark:'',
        is_level:1,
      };
      this.isthissave = false;
      this.fileList = [];
      this.getinfo();
      this.isshowdialog = true;
      this.disabled = false;
      this.isshowcheckbox = true;
    },
    async dosearch() {
      this.getlist(1);
    },
    async getlist(page) {
      this.searchinfo.page = page || this.searchinfo.page;
      this.isbusy = true;
      let res = await this.httpget('/system/entrust/index', this.searchinfo);
      if (res.code === 0) {
        this.list = res.data.data;
        this.list.forEach((item,index)=>{
          //样品类别
          this.yplist.forEach((yp,iyp)=>{
            if(item.sample_type == yp.cate_id){
              this.list[index].ypname = yp.title;
            }
          });
          //检测类别
          this.jclist.forEach((yp,iyp)=>{
            if(item.test_type == yp.cate_id){
              this.list[index].jcname = yp.title;
            }
          });
          //机种类别
          this.jzlist.forEach((yp,iyp)=>{
            if(item.machine_type == yp.cate_id){
              this.list[index].jzname = yp.title;
            }
          });
          //料件类别
          this.ljlist.forEach((yp,iyp)=>{
            if(item.material_name == yp.cate_id){
              this.list[index].ljname = yp.title;
            }
          });
          //材质
          this.czlist.forEach((yp,iyp)=>{
            if(item.texture == yp.cate_id){
              this.list[index].czname = yp.title;
            }
          });
          //单位
          this.dwlist.forEach((yp,iyp)=>{
            if(item.sample_unit == yp.cate_id){
              this.list[index].dwname = yp.title;
            }
          });
        })
        this.totalRows = res.data.total;
        this.isbusy = false;
      } else {
        this.isbusy = false;
      }

    },
    dosubmit(formName,dtype) {
      this.noticeformdata.dtype = dtype;
      if( dtype == 'save' ){
        this.isthissave = false;
      } else {
        this.isthissave = true;
      }
      this.$nextTick(()=>{
        this.$refs[formName].validate((valid,object) => {
          if (valid) {
            this.suresubmit();
          } else {
            setTimeout(()=>{
              var isError = document.getElementsByClassName('is-error');
              isError[0].querySelector('input').focus();
            },1);
            return false;
          }
        });
      });
    },
    async suresubmit() {
      this.isbusy = true;
      this.noticeformdata.select = this.multipleSelection;
      if( !this.noticeformdata.file_format && this.isthissave == true ){
        this.$message({type:"error",message:"请选择报告格式"});
        this.isbusy = false;
        return false;
      }
      if( this.noticeformdata.select.length == 0 && this.isthissave == true ){
        this.$message({type:"error",message:"请选择需求信息"});
        this.isbusy = false;
        return false;
      }
      if( this.noticeformdata.select.length > 0 && this.isthissave == true ){
        let ishasMessage = true;
        this.noticeformdata.select.forEach((item)=>{
          if( !item.number ){
            ishasMessage = false;
          }
        });
        if( !ishasMessage ){
          this.$message({type:"error",message:"请输入需求选项测试数量"});
          this.isbusy = false;
          return false;
        }
      }
      this.noticeformdata.file_path = this.fileList;
      let res = await this.httppost('/system/entrust/save', this.noticeformdata);
      if (res.code === 0) {
        this.$message({type: 'success', message: res.msg});
        this.getlist(1);
        this.noticeformdata = {};
        this.isshowdialog = false;
        this.isbusy = false;
      } else {
        this.isbusy = false;
        this.$message({type: 'error', message: res.msg});
      }
    },
    async setenable(item,field) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/entrust/setenable', {etid: item.entrust_id,field:field});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          let idx = this.list.findIndex((value) => {
            return value.entrust_id === item.entrust_id
          })
          if (idx !== -1) {
            if( field == 'status' ){
              this.list[idx].status = Math.abs(this.list[idx].status - 1);
            }
            if( field == 'is_apply' ){
              this.list[idx].is_apply = 1;
              this.list[idx].apply_time = res.data;
            }
          }
          this.isbusy = false;
          this.isshowdialog = false;
        } else {
          this.isbusy = false;
          this.$message({type: 'error', message: res.msg});
        }
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    async del(item) {
      this.$confirm(this.$t('global.areyousure')).then(async () => {
        this.isbusy = true;
        let res = await this.httpput('/system/entrust/delete', {etid: item.entrust_id});
        if (res.code === 0) {
          this.$message({type: 'success', message: res.msg});
          this.getlist();
        } else {
          this.$message({type: 'error', message: res.msg});
        }
        this.isbusy = false;
      }).catch(() => {
        this.isbusy = false;
        this.$message({type: 'info', message: 'Canceled'});
      });
    },
    uploadsuccess(res){
      if(res.code===0){
        var fileName = "";
        if(res.data[0]
            && res.data[0].indexOf(process.env.VUE_APP_IMG_DOMAIN)===-1
            && res.data[0].indexOf('http://')===-1){
          var fileArr = res.data[0].split('/');
          fileName = fileArr.pop();
          res.data[0] = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data[0];
        }
        //this.noticeformdata.file_path = res.data[0];
        this.fileList.push({
            name:fileName,
            url: res.data[0]
        });
      }else{
        this.$message({type: 'error', message: res.msg});
      }
    }
  },
  mounted() {
    this.getinfo();
    let that = this;
    setTimeout(function(){
      that.getlist(1);
    },500);
  },
  computed: {
    baseurl: {
      get: function () {
        return this.$store.getters.getSerdomain;
      }
    },
    langlist: {
      get: function () {
        return this.$store.getters.getLanglist;
      }
    },
  }
}
</script>


<style>
.ligreen{
  color:darkgreen;
}
.lired{
  color:darkred;
}
.showreport{
  margin-bottom:5px;
}
.showreport li{
  display:inline-block;
  margin-right:10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.uploader .el-upload:hover {
  border-color: #409EFF;
}
.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.preview {
  width: 178px;
  height: 50px;
  display: block;
}
.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 5px 0px 10px;
}
.el-table__header-wrapper table thead th:first-child label{display:none;}
</style>
