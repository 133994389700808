const tools = {
    /**
     * 乘法
     * @param arg1
     * @param arg2
     * @returns {number}
     */
    mathMul(arg1, arg2) {
        if (arg1 === 0 || arg2 === 0) return 0;
        let r1 = arg1.toString(), r2 = arg2.toString(), m, resultVal, d = arguments[2];
        m = (r1.split(".")[1] ? r1.split(".")[1].length : 0) + (r2.split(".")[1] ? r2.split(".")[1].length : 0);
        resultVal = Number(r1.replace(".", "")) * Number(r2.replace(".", "")) / Math.pow(10, m);
        return typeof d !== "number" ? Number(resultVal) : Number(resultVal.toFixed(parseInt(d)));
    },

    /**
     * 加法
     * @param arg1
     * @param arg2
     * @returns {*}
     */
    mathAdd(arg1, arg2) {
        if (arg1 === 0) return arg2;
        if (arg2 === 0) return arg1;
        arg1 = arg1.toString(), arg2 = arg2.toString();
        var arg1Arr = arg1.split("."), arg2Arr = arg2.split("."), d1 = arg1Arr.length == 2 ? arg1Arr[1] : "",
            d2 = arg2Arr.length == 2 ? arg2Arr[1] : "";
        var maxLen = Math.max(d1.length, d2.length);
        var m = Math.pow(10, maxLen);
        var result = Number(((arg1 * m + arg2 * m) / m).toFixed(maxLen));
        var d = arguments[2];
        return typeof d === "number" ? Number((result).toFixed(d)) : result;
    },

    /**
     * 除法
     * @param arg1
     * @param arg2
     * @returns {number}
     */
    mathDiv(arg1, arg2) {
        var r1 = arg1.toString(), r2 = arg2.toString(), m, resultVal, d = arguments[2];
        m = (r2.split(".")[1] ? r2.split(".")[1].length : 0) - (r1.split(".")[1] ? r1.split(".")[1].length : 0);
        resultVal = Number(r1.replace(".", "")) / Number(r2.replace(".", "")) * Math.pow(10, m);
        return typeof d !== "number" ? Number(resultVal) : Number(resultVal.toFixed(parseInt(d)));
    },

    /**
     *  减法 为了避免小数点二进制的坑爹问题
     * @param arg1
     * @param arg2
     * @returns {*|*|*|*}
     */
    mathSub(arg1, arg2) {
        return this.mathAdd(arg1, -Number(arg2), arguments[2]);
    },

    /**
     * 计算年龄  ismonth 为 true 则会同时返回月份
     * @param birthdaystr
     * @param ismonth
     * @returns {*}
     */
    getAge(birthdaystr, ismonth){
        var birthday=new Date(birthdaystr.replace(/-/g, "/"));
        var d=new Date();
        var age = d.getFullYear()-birthday.getFullYear()-((d.getMonth()<birthday.getMonth()|| d.getMonth()==birthday.getMonth() && d.getDate()<birthday.getDate())?1:0);
        if(!ismonth) return age;
        var month = 0 ;
        if(birthday.getMonth()<d.getMonth()) {
            month = d.getMonth() - birthday.getMonth();
        }else if (birthday.getMonth() == d.getMonth()){
            if(birthday.getDate() <= d.getDate()){
                month = 0 ;
            }else{
                month = 11 ;
            }
        }else if(birthday.getMonth() > d.getMonth()){
            month = 12 - (birthday.getMonth() - d.getMonth());
        }
        if(age == 0 && month == 0) month = 1;
        return [age,month];
    },

    /**
     * 用于export2Excel导出获取数据
     * @param {[array]} filterVal [要显示的字段,适用格式：'xxx', 'xxx.bb']
     * @param {[array]} jsonData [数据数组]
     */
    formatJson(filterVal, jsonData) {
        const arr = [];
        jsonData.forEach((v, index) => {
            arr[index] = [];
            filterVal.forEach((j) => {
                const location = j.indexOf('.');
                if (location > 0) {
                    const key = j.substr(0, location);
                    const subKey = j.substr(location + 1, j.length);
                    arr[index].push(v[key][subKey]);
                } else {
                    arr[index].push(v[j]);
                }
            })
        });
        return arr;
    },
    /**
     * 获取当前时间 YYYY-MM-DD
     * @returns {string}
     */
    getNowFormatDate(hoursFlag= false) {
        let date = new Date();
        let seperator1 = "-";
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let strDate = date.getDate();
        if (month >= 1 && month <= 9) month = "0" + month;
        if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate;

        let currentdate = year + seperator1 + month + seperator1 + strDate;
        if (hoursFlag) {
            var hours = date.getHours();       //获取当前小时数(0-23)
            if(hours >= 0 && hours < 10) hours= '0'+hours;
            var minutes = date.getMinutes();     //获取当前分钟数(0-59)
            if(minutes >=0 && minutes < 10) minutes= '0'+minutes;
            var seconds = date.getSeconds();   //huo当前的秒数
            if(seconds >= 0 && seconds < 10) seconds= '0'+seconds;
            currentdate += ' ' + hours + ':' + minutes + ':' + seconds;
        }
        return currentdate;
    },

    formatDate(value) {
        let date = new Date(value* 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    },

    formatDateNoYear(value) {
        let date = new Date(value* 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        let h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        let m = date.getMinutes();
        m = m < 10 ? ('0' + m) : m;
        let s = date.getSeconds();
        s = s < 10 ? ('0' + s) : s;
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    },

    toDecimal(x) {
        var f = parseFloat(x);
        if (isNaN(f)) {
            return;
        }
        f = Math.round(x * 100) / 100;
        return f;
    },
    formatAmount(n){
        var na = n.toString().split('.');
        var str_n=na[0];
        var decimal = na[1]==undefined ? na[1]:'00';
        var result="";
        while(str_n.length>3){
            result=","+str_n.slice(-3)+result;
            str_n=str_n.slice(0,str_n.length-3)
        }
        if(str_n){
            str_n = str_n+result;
        }
        return str_n+'.'+decimal;
    }
};
export default tools;
